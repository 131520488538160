import {Col, Row, Form, Stack, Modal, Button} from "react-bootstrap";
import { useForm } from "react-hook-form";
import FormDatePicker from "@components/form/FormDatePicker/FormDatePicker";
import DatesForm from "./DatesForm/DatesForm";
import ParticipationOptionsForm from "./ParticipationOptionsForm/ParticipationOptionsForm";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import useChangeInvitation from "@requests/communication/invitations/useChangeInvitation";
import { useSnapshot } from "valtio";
import invitationsProxy from "@proxies/invitations";
import ExpediteurSelection from "../../../ExpediteurSelection/ExpediteurSelection";
import TypeInvitationSelection from "../../../TypeInvitationSelection/TypeInvitationSelection";
import DestinataireResponseInvitationSelection
    from "../../../DestinataireResponseInvitationSelection/DestinataireResponseInvitationSelection";
import auth from '@services/auth';
import $ from "jquery";
import {BtSwal} from "../../../../../utils/alerts/sweetAlert";
import moment from "moment";
import FormTypeahead from "../../../../../components/form/FormTypeahead/FormTypeahead";
import {codePostalPattern} from "../../../../../functions/patterns";
import {useDebounce} from "use-debounce";
import useGetVilleFromCodePostal from "../../../../../requests/geoname/useGetVilleFromCodePostal";
import ResponsableSelection from "../../../MailsView/ResponsableSelection";
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import ExpediteurSettings from "../../../../ParametresView/ExpediteursSettings/ExpediteurSettings";
import DestinataireReponseInvitationSettings
    from "../../../../ParametresView/DestinataireReponseInvitationSettings/DestinataireReponseInvitationSettings";
import PersonnalisationInvitationModal
    from "../../../../../views/communication/InvitationsView/EditModal/ReglagesStep/PersonnalisationReponseInvitation/PersonnalisationInvitationModal";
import useGetTva from "../../../../../requests/parametresPaiement/tva/useGetTva";

const ReglagesStep = forwardRef((_, ref) => {
    const [showModalSettings, setShowModalSettings] = useState(false);
    const [currentComponentSettings, setCurrentComponentSettings] = useState(null);
    const changeInvitation = useChangeInvitation();
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const snapAuth = useSnapshot(auth);
    const convertedInvitation = useMemo(
        () => ({
            ...invitationsSnapshot.invitation,
            auteur:snapAuth.utilisateur,
            agence:snapAuth.agence,
            responsablePlaceLimite : invitationsSnapshot.invitation?.responsablePlaceLimite?.id ? invitationsSnapshot.invitation?.responsablePlaceLimite : null,
            tabPeriodes: Object.values(invitationsSnapshot.invitation?.tabPeriodes ?? {}),
            tabOptionsParticipation: Object.values(invitationsSnapshot.invitation?.tabOptionsParticipation ?? {}),
        }),
        [invitationsSnapshot.invitation],
    );
    const [previewVal, setPreviewVal] = useState(null);
    const tva = useGetTva();
    const { control, register, trigger, unregister, handleSubmit, reset, setValue, getValues , formState: { errors }, watch } = useForm({
        defaultValues: convertedInvitation,
    });
    const code = watch('codePostal');
    const ville = watch('ville');
    const placeLimite = watch('nbParticipantsMax');
    const [searchVilleParams] = useDebounce(useMemo(() => ({code, ville}), [code, ville]), 300);
    const autocompletionCodePostal = useGetVilleFromCodePostal({...searchVilleParams});
    const id = watch('id');
    const boolPaiementEnLigneIsUtilise = watch('paiementEnLigneIsUtilise');

    useEffect(() => {
        console.log('converted => ', convertedInvitation);
        reset(convertedInvitation ?? {});
    }, [reset, convertedInvitation]);

    useEffect(() => {
        if (placeLimite == "0"){
            unregister("responsablePlaceLimite")
        }
    }, [placeLimite])

    useImperativeHandle(ref, () =>
        ({
        save: async () => {
            let done = false;
            await handleSubmit(async (data) => {
                if (data.tabOptionsParticipation.length === 1 && (data.tabOptionsParticipation[0].designation === "" || data.tabOptionsParticipation[0].designation == null)){
                    delete data.tabOptionsParticipation;
                    data.tabOptionsParticipation = null;
                }
                let dateRelance = data.dateRelance;
                let dateReponseS = data.dateReponse;
                let dateRappel = data.dateRappel;
                let dateDebutEvent = data.tabPeriodes[0].debut.split(" ")[0];

                if (Object.keys(data.tabDestinatairesReponseInvitation).length === 0){
                    done = false;
                }
                //vérif des dates
                else if (moment(dateRelance, "DD/MM/YYYY") > moment(dateDebutEvent, "DD/MM/YYYY")
                    || moment(dateReponseS, "DD/MM/YYYY") > moment(dateDebutEvent, "DD/MM/YYYY") ||
                    moment(dateRappel, "DD/MM/YYYY") > moment(dateDebutEvent, "DD/MM/YYYY")
                    // || moment(dateRelance, "DD/MM/YYYY") < moment()
                    // || moment(dateReponseS, "DD/MM/YYYY") < moment()
                ){
                    if (moment(dateRelance, "DD/MM/YYYY") > moment(dateDebutEvent, "DD/MM/YYYY")){
                        BtSwal.fire("La date de relance est incorrect par rapport à la date de début de l'évènement.", '', 'error')
                    }else if (moment(dateReponseS, "DD/MM/YYYY") > moment(dateDebutEvent, "DD/MM/YYYY")){
                        BtSwal.fire("La date de réponse requise est incorrect par rapport à la date de début de l'évènement.", '', 'error')
                    }else {
                        BtSwal.fire("La date de rappel est incorrect par rapport à la date de début de l'évènement.", '', 'error')
                    }
                }
                else if (dateReponseS && dateRelance && moment(dateReponseS, "DD/MM/YYYY") <= moment(dateRelance, "DD/MM/YYYY")){
                    BtSwal.fire("La date de relance est incorrect par rapport à la date de réponse requise avant le.", '', 'error')
                }
                else if (moment(dateDebutEvent, "DD/MM/YYYY") < moment()){
                    BtSwal.fire("La date de début de l'évènement choisie est déjà passé", '', 'error')
                    done = false;
                }
                else if (data.paiementEnLigneIsUtilise){
                    if (data.tabOptionsParticipation != null){
                        let msgError = ""
                        done = false;
                        data.tabOptionsParticipation.forEach(function (value, index){
                            if (value?.tabArticles){
                                Object.values(value?.tabArticles)?.forEach(item => {
                                    if (item?.montant > 0){
                                        done = true;
                                    }
                                })
                            }
                        })
                        if (done){
                            try {
                                const result = await changeInvitation.mutateAsync(data);
                                invitationsProxy.invitation = result;
                                done = true;
                            } catch {
                                done = false;
                                reset(convertedInvitation ?? {})
                            }
                        }else {
                            if (msgError != ""){
                                BtSwal.fire("Pour utiliser les services de stripe, le montant ne peut pas être inférieur à 0.", '', 'error')
                            }else {
                                BtSwal.fire("Pour utiliser les services de stripe, au moins 1 montant doit être supérieur à 0", '', 'error')
                            }
                        }
                    }else {
                        BtSwal.fire("Pour utiliser les services de stripe, au moins 1 montant doit être supérieur à 0 et une désignation doit être choisie.", '', 'error')
                    }
                }
                else {
                    try {
                        const result = await changeInvitation.mutateAsync(data);
                        invitationsProxy.invitation = result;
                        done = true;
                    } catch {
                        done = false;
                        reset(convertedInvitation ?? {})
                    }
                }
            })();
            return done;
        },
    }));
    $( document ).ready(function() {
        if($("#focus").val() == ""){
            $("#focus").focus()
        }
    });


    return <div className='mw-800px mx-auto'>
        <Form onSubmit={handleSubmit((data) => {
        })}>
            <h2 className='fw-bolder d-flex align-items-center text-dark mb-10'>
                Réglages
            </h2>
            <Form.Group>
                <Form.Label className='required'>Sujet du mail</Form.Label>
                <Form.Control maxLength={256} id={"focus"}  {...register('sujet',  { required: true })} className={`form-control-solid `} placeholder='Sujet du mail' />
                {errors.sujet && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Form.Group>
            <DatesForm getValues={getValues} setValue={setValue} control={control} rules={{ required: true }} name='tabPeriodes' />
            {errors.tabPeriodes && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            <Row className='mt-5'>
                <Col sm={6}>
                    <Form.Group>
                        <Form.Label>Réponse requise avant le</Form.Label>
                        <FormDatePicker id={"reponseSouhaiteAvantLe"} control={control} name='dateReponse' solid />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Date de relance</Form.Label>
                        <FormDatePicker id={"relanceLe"} control={control} name='dateRelance' solid />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col sm={6}>
                    <Form.Group>
                        <Form.Label>Date de rappel :</Form.Label>
                        <FormDatePicker id={"dateRappel"} control={control} name='dateRappel' solid />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label className='required'>Expéditeur</Form.Label>
                        <div className="d-flex align-items-center">
                            <div className="w-100">
                                <ExpediteurSelection control={control}  name='expediteur' rules={{ required: true }} />
                            </div>
                            <span
                                title={"Ajouter un expéditeur"}
                                style={{fill: "black"}}
                                onClick={() => {
                                    setCurrentComponentSettings(<ExpediteurSettings/>)
                                    setShowModalSettings(true)
                                }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
                        </div>
                        {errors.expediteur && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <Form.Group>
                        <Form.Label className='required'>Type d'invitation</Form.Label>
                        <TypeInvitationSelection control={control} name='type' rules={{ required: true }} />
                        {errors.type && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Thème de l'évènement</Form.Label>
                        <Form.Control maxLength={256} {...register('theme')} className={`form-control-solid `} placeholder="Thème de l'invitation" />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className='mt-5'>
                <Form.Label className='required'>Choisissez une ou plusieurs adresses pour la réception des réponses : </Form.Label>
                <div className="d-flex align-items-center">
                    <div className="w-100">
                        <DestinataireResponseInvitationSelection control={control} name='tabDestinatairesReponseInvitation' rules={{ required: true }} />
                    </div>
                    <span
                        title={"Ajouter une adresse"}
                        style={{fill: "black"}}
                        onClick={() => {
                            setCurrentComponentSettings(<DestinataireReponseInvitationSettings/>)
                            setShowModalSettings(true)
                        }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
                </div>
                {errors.tabDestinatairesReponseInvitation && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Form.Group>
            {/*<Form.Group className='mt-5'>*/}
            {/*    <Form.Label className='required'>Signature invitation</Form.Label>*/}
            {/*    <SignatureSelection control={control} name='signature' rules={{ required: true }} />*/}
            {/*    {errors.signature && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}*/}
            {/*</Form.Group>*/}
            <Row>
                <Col sm={6}>
                    <Form.Group className='mt-5'>
                        <Form.Label className='required'>Nombre maximum de participants <br/> <span className="alert alert-info p-1">0 = nombre de participants illimités</span></Form.Label>
                        <Form.Control defaultValue={0} type="number"  {...register('nbParticipantsMax',  { required: true })} className={`form-control-solid `} placeholder='Nombre maximum de participants' />
                        {errors.nbParticipantsMax && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Form.Group>
                </Col>
                {placeLimite != 0 &&
                    <Col>
                        <Form.Group className='mt-5'>
                            <Form.Label className='required mb-8'>Responsable des places</Form.Label>
                            <ResponsableSelection control={control}  name='responsablePlaceLimite' rules={{ required: true }} />
                            {errors.responsablePlaceLimite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </Form.Group>
                    </Col>
                }
            </Row>
            <Form.Check
                className="mt-5 required"
                type='switch'
                label="Afficher le nombre de participants sur l'application mobile"
                id='checkNbParticipantsIsafficheSurMobile'
                {...register('nbParticipantsIsafficheSurMobile')}
            />

            <h2 className='fw-bolder d-flex align-items-center text-dark my-10'>
                Options de participation
            </h2>
            <Form.Check
                type='switch'
                label="Afficher le bloc «informations complémentaires» lors de la réponse du destinataire"
                id='checkInfoComplementaire'
                {...register('champInformationComplementaireReponseIsAffiche')}
            />
            <Form.Check
                type='switch'
                className='mt-5'
                label="Afficher la liste «combien êtes vous à venir»"
                id='checkCombienEtesVousAVenir'
                {...register('listeCombienEtesVousAVenirIsAffiche')}
            />
            { snapAuth?.parametreFacturation?.compteStripeIsConfigure &&  snapAuth?.parametreFacturation?.urlTemplateFacture && tva?.data?.nbLignesTotales != 0 ?
                <Form.Check
                    type='switch'
                    className='mt-5'
                    // disabled={true}
                    label="Autoriser le paiement CB en ligne - Frais d'utilisation stripe 1,9% + 0,50 €TTC"
                    id='checkLinscription'
                    {...register('paiementEnLigneIsUtilise')}
                />
                :
                <Form.Check
                    title="Vous devez configurer stripe dans les paramètres pour utiliser cette option"
                    type='switch'
                    className='mt-5'
                    disabled={true}
                    label="Autoriser le paiement CB en ligne - Frais d'utilisation stripe 1,9% + 0,50 €TTC"
                    id='checkLinscription'
                    {...register('paiementEnLigneIsUtilise')}
                />

            }

            <span hidden={!boolPaiementEnLigneIsUtilise}>
                <Form.Check
                    type='switch'
                    className='mt-5'
                    label="Autoriser le paiement sur place (par chèque ou espèce)"
                    id='checkPaiementSurPlaceIsAutorise'
                    {...register('paiementSurPlaceIsAutorise')}
                />
            </span>

            <ParticipationOptionsForm
                    errors={errors}
                    unregister={unregister}
                    getValues={getValues}
                    setValue={setValue}
                    boolPaiementEnLigneIsUtilise={boolPaiementEnLigneIsUtilise}
                    control={control}
                    register={register}
                    trigger={trigger}
                    name='tabOptionsParticipation'
            />

            {/*Ancienne version de l'édition de la page de réponse destinataire avec modal séparée en full screnn*/}
            {/*<Col>*/}
            {/*    <Button*/}
            {/*        onClick={() => {*/}
            {/*            setPreviewVal(getValues())*/}
            {/*            invitationsProxy.showPersonnalisationInvitation();*/}
            {/*        }}*/}
            {/*        variant={"primary"}*/}
            {/*    >*/}
            {/*        Personnaliser la page de réponse du destinataire*/}
            {/*    </Button>*/}
            {/*</Col>*/}
            <h2 className='fw-bolder d-flex align-items-center text-dark my-10'>
                Lieu de l'évènement
            </h2>
            <Form.Group>
                <Form.Label>Prélude de l'adresse</Form.Label>
                <Form.Control
                    maxLength={200}
                    className='form-control-solid'
                    placeholder="Sur le site de ... au restaurant ... à l'aéroport ..."
                    {...register('preludeAdresse')}
                />
            </Form.Group>
            <Row>
                <Col className='mt-5'>
                    <Form.Group>
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control
                            maxLength={200}
                            as='textarea'
                            className='form-control-solid'
                            rows={5}
                            {...register('adresse')}
                        />
                    </Form.Group>
                </Col>
                <Col className='mt-5' md={3}>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Code postal</Form.Label>
                            <FormTypeahead solid
                                           key={id}
                                           maxLength={5}
                                           id='codePostal'
                                           control={control}
                                           name='codePostal'
                                           rules={{required: false, pattern: codePostalPattern()}}
                                           options={autocompletionCodePostal.data?.postalCodes}
                                           autocompleteDisabled={false}
                                           labelKey={option => option.postalCode}
                                           textKey={option => `${option.postalCode} - ${option.placeName}`}
                                           filterBy={() => true}
                                           onSelected={(selected) => {
                                               if (selected && selected.postalCode && selected.placeName) {
                                                   setValue("codePostal", selected.postalCode.slice(0,5))
                                                   setValue("ville", selected.placeName)
                                               }

                                           }}
                            />
                            {errors.codePostal && <Form.Text className='text-danger'>Code postal invalide</Form.Text>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ville</Form.Label>
                            <Form.Control maxLength={50} className='form-control-solid' {...register('ville')} />
                        </Form.Group>
                    </Stack>
                </Col>
            </Row>
        </Form>
        <Modal
            show={showModalSettings}
            onHide={() => setShowModalSettings(false)}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Paramètres mail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {currentComponentSettings}
            </Modal.Body>
        </Modal>
        <PersonnalisationInvitationModal
            invitationEdited={previewVal}
        />

    </div>;
})

export default ReglagesStep;