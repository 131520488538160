import React from "react";

export function renderSwitchInscriptionInscriptionLibreEtPreview(champsPerso, key, color, texteComplementaire = "") {
    if (champsPerso && champsPerso[key] && champsPerso[key].state.active) {
        switch (key) {
            case "titre1" :
                return <span dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}>
                </span>;
            case "titre2" :
                return <span dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}>
                </span>;
            case "sousTexteTitre2" :
                return <p className="text-white" dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html.replace("<p>", "<p>"+texteComplementaire)
                }}>
                </p>;
            case "titreDecline" :
                return <span dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}>
                </span>;
            case "texteDecline" :
                return <div dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }} className='mt-5 mx-10'></div>;
            case "champs1":
                return <div className="position-relative p-10 pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "champs2":
                return <div className="position-relative p-10 pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "champs3":
                return <div className="position-relative pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "champs4":
                return <div className="position-relative p-10 pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "default":
                return "";
        }
    }
}