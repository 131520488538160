import {Button, Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import {ReactComponent as UserCrow} from "../../../assets/icons/user-group-crown.svg";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import auth from "../../../services/auth";
import {useEffect, useState} from "react";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import useGetQueryParticipants from "../../../requests/communication/speedBusiness/useGetQueryParticipants";
import LoadingView from "../../../views/LoadingView/LoadingView";
import useGetReceptionnistes from "../../../requests/communication/speedBusiness/useGetReceptionnistes";

export default function LancementSpeedBusinessModal(){

    const speedSnap = useSnapshot(speedBusinessProxy);
    const snapAuth = useSnapshot(auth);
    const [nbParticipantsIsOk, setNbParticipantsIsOk] = useState(false);
    const [nbMaitreTempsAndSuppIsOk, setNbMaitreTempsAndSuppIsOk] = useState(false);
    const receptionnistes = useGetReceptionnistes();
    const [nbReceptionnistes, setNbReceptionniste] = useState(0);
    const participantsPresentEtEnRetard = useGetQueryParticipants(
        speedSnap.speedBusiness?.id,
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                //0==Présence 1==Absence 2==En retard
                value: "0,2",
                regex: false,
            },
        },
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            search: {
                //1==false 0==true
                value: "",
                regex: false,
            },
        },
        "presentEtEnRetard"
    );
    const participantsMaitreDutempsSuppleant = useGetQueryParticipants(
        speedSnap.speedBusiness?.id,
        {
            data: "isMaitreDuTempsSuppleant",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                value: 0,
                regex: false,
            },
        },
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        "maitreDuTempsSuppleant"
    )

    const participantsMaitreDuTemps = useGetQueryParticipants(
        speedSnap.speedBusiness?.id,
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            logic:"OR",
            search: {
                //1==false 0==true
                value: 0,
                regex: false,
            },
        },
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        "maitreDuTempsEtSuppleant",
        {
            data: "isMaitreDuTempsSuppleant",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            logic:"OR",
            search: {
                //1==false 0==true
                value: 0,
                regex: false,
            },
        },
    );
    useEffect(() => {
        if (participantsPresentEtEnRetard?.data?.nbLignesTotales < (speedSnap?.speedBusiness?.nombreTablesChoisies * speedSnap?.speedBusiness?.nombrePersonnesMinParTable)){
            setNbParticipantsIsOk(false)
        }else {
            setNbParticipantsIsOk(true)
        }
    }, [participantsPresentEtEnRetard?.data?.nbLignesTotales]);

    useEffect(() => {
        let nbMaitreDuTempsEtSuppleantRequis = speedSnap?.speedBusiness?.nombreTablesChoisies;
        if ((speedSnap?.speedBusiness?.nombreTablesChoisies + 1) == speedSnap?.speedBusiness?.nombreTours){
            nbMaitreDuTempsEtSuppleantRequis = nbMaitreDuTempsEtSuppleantRequis + (speedSnap?.speedBusiness?.nombreTablesChoisies-1);
        }
        if (nbMaitreDuTempsEtSuppleantRequis != participantsMaitreDuTemps?.data?.nbLignesTotales){
            setNbMaitreTempsAndSuppIsOk(false);
        }else {
            setNbMaitreTempsAndSuppIsOk(true);
        }
    }, [participantsPresentEtEnRetard?.data?.nbLignesTotales, participantsMaitreDuTemps?.data?.nbLignesTotales, speedSnap?.speedBusiness?.nombreTablesChoisies]);

    useEffect(async () => {
        let dataReceptionniste = await receptionnistes.mutateAsync(speedSnap?.speedBusiness?.id);
        setNbReceptionniste(dataReceptionniste?.nbLignesTotales);
    }, [speedSnap?.speedBusiness?.id]);

    if (participantsPresentEtEnRetard?.isLoading){
        return <LoadingView/>
    }

    return <Modal
        size={"lg"}
        show={speedSnap.showLancementSpeedBusinessModal}
        onHide={() => {
            speedBusinessProxy.showLancementSpeedBusinessModal = false
        }}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <h2>
                Lancer la rencontre
            </h2>
        </Modal.Header>
        <Modal.Body>
            <div style={{height:"100%"}}>
                {nbReceptionnistes > 0 ? <Button onClick={() => {
                    speedBusinessProxy.showGestionReceptionniste(speedSnap.speedBusiness);
                }} className="mb-5">Gestion des réceptionnistes</Button> : ""}
                <div style={{
                    height:"100%"
                }} className="d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-wrap w-200px">
                        <div style={{
                            height: 100,
                            width: 100,
                            padding: 0,
                            borderRadius: 0,
                            display: "flex",
                            justifyContent:"center",
                            alignItems:"center",
                            cursor: "pointer",
                            border: "solid 1px white",
                        }}
                             onClick={()=>{
                                 speedBusinessProxy.showPresences(speedSnap?.speedBusiness);
                             }}
                             className={`${nbParticipantsIsOk ? "bg-success" : "bg-danger" }`}>
                            <div style={{
                                width: "100%"
                            }}>
                                <i
                                    style={{
                                        color: "white",
                                        margin:"auto",
                                        width: "fit-content",
                                        display: "block"
                                    }}
                                    className="fa fa-2x fa-users"></i>
                                <small style={{
                                    display: "block",
                                    color: "white",
                                    width: "100%",
                                    textAlign: "center"
                                }}>Participants</small>
                            </div>
                        </div>
                        <div style={{
                            height: 100,
                            width: 100,
                            padding: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 0,
                            cursor: "pointer",
                            border: "solid 1px white",
                        }} className={`${nbMaitreTempsAndSuppIsOk ? "bg-success" : "bg-danger"}`} onClick={() => {
                            speedBusinessProxy.showMaitreDuTempsTutoAndSendMail(speedSnap?.speedBusiness);
                        }}>
                            <div style={{
                                width: "100%"
                            }}>
                                <div style={{
                                    margin: "auto",
                                    width: 30,
                                    fill: "white"
                                }}>
                                    <UserCrow/>
                                </div>
                                <small style={{
                                    display: "block",
                                    color: "white",
                                    width: "100%",
                                    textAlign: "center"
                                }}>Maître du temps</small>
                            </div>
                        </div>
                        <br/>
                        <div style={{
                            height: 100,
                            width: 100,
                            padding: 0,
                            borderRadius: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "solid 1px white",
                        }}
                             onClick={() => {
                                 if (nbMaitreTempsAndSuppIsOk && nbParticipantsIsOk) {
                                     speedSnap.showEmailAndSmsPassage(speedSnap.speedBusiness)
                                 }
                             }}
                             className={`${(nbMaitreTempsAndSuppIsOk && nbParticipantsIsOk) ? "bg-primary cursor-pointer" : "bg-gray-400 cursor-not-allowed"} `}>
                            <div style={{
                                width: "100%"
                            }}>
                                <i style={{
                                    color: "white",
                                    margin:"auto",
                                    width: "fit-content",
                                    display: "block"
                                }} className="fa fa-2x fa-envelope"></i>
                                <small style={{
                                    display: "block",
                                    color: "white",
                                    width: "100%",
                                    textAlign: "center"
                                }}>Ordre de passage</small>
                            </div>
                        </div>
                        <div style={{
                            height: 100,
                            width: 100,
                            padding: 0,
                            borderRadius: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "solid 1px white",
                            color: "white"
                        }}
                             onClick={() => {
                                 if (nbMaitreTempsAndSuppIsOk && nbParticipantsIsOk) {
                                     if (speedSnap?.speedBusiness?.ref) {
                                         if ((speedSnap?.speedBusiness?.nombreTablesChoisies + 1) == speedSnap?.speedBusiness?.nombreTours && ((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedSnap?.speedBusiness?.nombreTablesChoisies) - 1) != participantsMaitreDutempsSuppleant?.data?.nbLignesTotales)) {
                                             BtSwal.fire({
                                                 title: `<b>Le nombre de maitres du temps suppléant n'est pas adapté en fonction du nombre de présents</b>`,
                                                 showDenyButton: true,
                                                 confirmButtonText: `Corriger`,
                                                 denyButtonText: `Annuler`,
                                             }).then((result) => {
                                                 if (result.isConfirmed) {
                                                     speedBusinessProxy.showLastCheckSuppleant(speedSnap?.speedBusiness)
                                                 }
                                             })
                                         } else {
                                             BtSwal.fire({
                                                 title: `<b>Êtes-vous sûr de vouloir lancer les rencontres ?</b> <br> <br> <small>Attention, tout ajout de participants après cette étape entraînera des erreurs de positionnement.</small>`,
                                                 showDenyButton: true,
                                                 confirmButtonText: `Oui`,
                                                 denyButtonText: `Annuler`,
                                             }).then((result) => {
                                                 if (result.isConfirmed) {
                                                     let base_url = window.location.origin;
                                                     window.open(base_url + "/" + snapAuth?.agence?.dossierUpload + "/speedBusiness/presentation?ref=" + speedSnap.speedBusiness.ref, '_blank');
                                                 }
                                             })
                                         }
                                     }
                                 }
                             }}
                             className={`${(nbMaitreTempsAndSuppIsOk && nbParticipantsIsOk) ? "bg-primary cursor-pointer" : "bg-gray-400 cursor-not-allowed"} `}>
                            <div
                                style={{
                                    width: "100%"
                                }}>
                                <i
                                    style={{
                                        color: "white",
                                        margin: "auto",
                                        width: "fit-content",
                                        display: "block"
                                    }}
                                    className="fa fa-2x fa-reply"></i>
                                <small
                                    style={{
                                        width: "fit-content",
                                        display: "block",
                                        margin: "auto"
                                    }}>
                                    GO
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Modal.Body>
    </Modal>
}