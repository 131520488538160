import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import React, { useEffect, useMemo, useRef, useState } from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import ProfilSelection from "./ProfilFilter/ProfilFilter";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import { useMetaCheckboxes } from "@components/MetaTable/useMetaCheckboxes";
import useInscrireParticipant from "@requests/communication/speedBusiness/useInscrireParticipant";
import BlockUI from "@components/BlockUI/BlockUI";
import useDesinscrireParticipant from "@requests/communication/speedBusiness/useDesinscrireParticipant";
import { useDatatableColumns } from "@components/MetaTable/useDatatableColumns";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import annuaireProxy from "@proxies/annuaire";
import ProfilPartenaireFilter from "./ProfilFilter/ProfilPartenaireFilter";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import toast from "react-hot-toast";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import {useSnapshot} from "valtio";
import MetaTableDestinataireByInvitation
    from "../../../views/SpeedBusinessView/DestinatairesStep/MetaTableDestinataireByInvitation";
import useGetInvitationById from "../../../requests/communication/invitations/useGetInvitationById";
import invitationsProxy from "../../../proxies/invitations";
import {count} from "react-table/src/aggregations";
import $ from "jquery";
import useGetChampPerso from "../../../requests/annuaire/useGetChampPerso";
import Select2 from "../../../components/Select2/Select2";
import useGetParticipants from "../../../requests/communication/speedBusiness/useGetParticipants";

function DestinatairesStep({ baseRoute, id, onlyPartenaireProfil = false, termine, setTermine }) {
    const { register, watch, setValue } = useForm();
    const searchContact = watch('searchContact');
    const [debouncedSearchContact] = useDebounce(searchContact, 300);
    const searchDestinataire = watch('searchDestinataire');
    const [debouncedSearchDestinataire] = useDebounce(searchDestinataire, 300);
    const speedSnap = useSnapshot(speedBusinessProxy);
    const [selectedProfil, setSelectedProfil] = useState();
    const [voirDestinataires, setVoirDestinataires] = useState(true);
    const contactsCheckboxes = useMetaCheckboxes();
    const destinatairesCheckboxes = useMetaCheckboxes();
    const participants = useGetParticipants();
    const destinataireSectionRef = useRef();
    const contactSectionRef = useRef();
    const [nbParticipants, setNbParticipants] = useState(0);
    const [update, setUpdate] = useState(0);

    useEffect(()=>{
        if (termine && nbParticipants){
            if (nbParticipants < (speedSnap?.speedBusiness?.nombreTablesChoisies * speedSnap?.speedBusiness?.nombrePersonnesMinParTable)){
                BtSwal.fire(
                    {
                        title: "Attention le nombre minimum de participants ("+speedSnap?.speedBusiness?.nombreTablesChoisies * speedSnap?.speedBusiness?.nombrePersonnesMinParTable+") n'a pas été atteint !",
                        showDenyButton: true,
                        confirmButtonText: `Quitter la selection des participants`,
                        denyButtonText: `Retour`,
                        icon: 'warning',
                    }).then((result) => {
                    if (result.isConfirmed){
                        speedBusinessProxy.showEditSpeedBusinessModal = false;
                        setTermine(false);
                    }else {
                        setTermine(false);
                    }
                })
            }
        }
    }, [termine])
    const scrollToContacts = () => contactSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    const scrollToDestinataires = () => destinataireSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    useEffect(() => {
        if (baseRoute == "rencontreSpeedBusiness" && (contactsCheckboxes.getIds().length !== 0 || destinatairesCheckboxes.getIds().length !== 0 || contactsCheckboxes.isSelectingAll || destinatairesCheckboxes.isSelectingAll)){
            speedBusinessProxy.destinataireIsChecked = true;
            speedBusinessProxy.destinataireAlertIsAdd = contactsCheckboxes.getIds().length !== 0 || contactsCheckboxes.isSelectingAll;
        }else if (baseRoute == "rencontreSpeedBusiness"){
            speedBusinessProxy.destinataireIsChecked = false;
        }
    }, [contactsCheckboxes, destinatairesCheckboxes])
    useEffect(() => {
        contactsCheckboxes.reset();
    }, [selectedProfil, debouncedSearchContact]);
    useEffect(() => {
        destinatairesCheckboxes.reset();
    }, [debouncedSearchDestinataire]);
    useEffect(async () => {
        if (id){
            let dataParticipants = await participants.mutateAsync(id)
            setNbParticipants(dataParticipants?.nbLignesTotales)
        }
    }, [id, update]);
    const colSearch = useMemo(() => {
        return {
            'personne.tabProfils.id': selectedProfil?.id,
        };
    }, [selectedProfil]);
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const [idTabInvitationFilter, stateIdTabInvitationFilter] = useState("");
    const [idEtatPaiement, stateIdEtatPaiement] = useState("");
    const [idOptionParticipation, stateIdOptionParticipation] = useState("");
    const colSearchByInvitation = useMemo(() => {
        return {
            'etatInvitation': idTabInvitationFilter,
            'etatPaiement': idEtatPaiement,
            'optionParticipation.designation': idOptionParticipation,
            'destinataire.personne.tabProfils.id':selectedProfil?.id
        };
    }, [idTabInvitationFilter, idEtatPaiement, selectedProfil, idOptionParticipation]);

    const convertTabOptionsParticipation = invitationsSnapshot.invitation?.tabOptionsParticipation ? Object.entries(invitationsSnapshot.invitation?.tabOptionsParticipation).map(exp => exp[1]) : [];

    const data = useMemo(() => {
        return convertTabOptionsParticipation.map(exp => ({
            id: exp.id,
            text: `${exp.designation} -- ${exp.montant} €TTC`,
        }));
    }, [invitationsSnapshot.invitation?.id, invitationsSnapshot.invitation?.tabOptionsParticipation]);
    const champsPerso = useGetChampPerso("");
    const contactsColumnsByInvitation = useMemo(() => {

        let tmp =  [
            {
                Header: () => <div className='my-2'>
                    <Form.Check
                        className='form-check form-check-custom'
                        checked={contactsCheckboxes.isSelectingAll}
                        onChange={() => contactsCheckboxes.toggleSelectAll()}
                    />
                </div>,
                id: 'selector',
                Cell: ({ row }) => <Form.Check
                    className='form-check form-check-custom'
                    checked={contactsCheckboxes.isItemChecked(row.original.destinataire.personne.id)}
                    onChange={() => contactsCheckboxes.toggleItem(row.original.destinataire.personne.id)}
                />,
                width: 60,
                disableResizing: true,
                disableSearch: true,
                noWrap: true,
            },
            {
                Header: () => "",
                id: 'wrench',
                Cell: ({ row }) => <div className="d-flex justify-content-center">
                    <a
                        onClick={() => annuaireProxy.showPersonne(row.original.destinataire.personne, false, "personne", true)}
                        className='svg-icon svg-icon-1 cursor-pointer'><Art005 /></a></div>,

                width: 60,
            },
            {
                Header: 'Id',
                accessor: 'destinataire.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPaiement',
                accessor: 'etatPaiement',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                hidden: true
            },
            {
                Header: 'tabAccompagnants',
                accessor: 'tabAccompagnants.destinataire.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'tabAccompagnantsNomPrenom',
                accessor: 'tabAccompagnants.destinataire.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'optionParticipation',
                accessor: 'optionParticipation.id',
                hidden: true
            },
            {
                Header: 'optionParticipation',
                accessor: 'optionParticipation.designation',
                hidden: true
            },
            {
                Header: 'prenomNom',
                accessor: 'destinataire.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'destinataire.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'destinataire.personne.prenom',
                hidden: true,
            },
            // {
            //     Header: 'nom',
            //     accessor: 'destinataire.personne.nom',
            //     hidden: true,
            // },
            {
                Header: 'Destinataire',
                accessor: 'destinataire.personne.nom',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(row.original.destinataire.personne.prenom + " " + row.original.destinataire.personne.nom);
                    result.push(<span> <i onClick={() => {
                        invitationsSnapshot.showInfoPersonneForSuiviInvitation(row.original.destinataire);
                    }} className="cursor-pointer fa fa-info-circle"></i></span>)
                    // result.push((row.original.destinataire.tel1 ? row.original.destinataire.tel1 + " " : "") + row.original.destinataire.tel2 ? row.original.destinataire.tel2 : "")
                    return result;
                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNom(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher prénom nom'
                    />
                },
            },
            {
                Header: ({row, label}) => (
                    <>
                        <span className="me-2">Réponse invitation</span>
                    </>
                ),
                id: 'reponseInvitation',
                accessor: 'tabEtatsInvitation',
                minWidth: 300,
                maxWidth: 300,
                Cell: ({row}) => {
                    return row.original.tabEtatsInvitation.find(item => item.actif).libelle

                },
                Footer: info => {
                    return <Select2
                        data={tabEtatsInvitationSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre réponse invitation'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdTabInvitationFilter(selected)
                        }}/>
                },
            },
            {
                Header: ({row}) => {
                    return <div className="d-inline">
                        <span className="me-2">Paiement</span>
                        {/*<span*/}
                        {/*    onClick={() => {*/}
                        {/*        BtSwal.fire(*/}
                        {/*            {*/}
                        {/*                title: "Voulez-vous envoyer une relance de paiement pour tous ceux qui n'ont pas finaliser leur inscription ?",*/}
                        {/*                showDenyButton: true,*/}
                        {/*                confirmButtonText: `Oui`,*/}
                        {/*                denyButtonText: `Annuler`,*/}
                        {/*                icon: 'info',*/}
                        {/*            }).then((result) => {*/}
                        {/*            if (result.isConfirmed){*/}
                        {/*                sendRelancePaiementAll.mutate(row?.original?.id)*/}
                        {/*            }*/}
                        {/*        })*/}
                        {/*    }}*/}
                        {/*    title="Relancer tous les paiements en attente"*/}
                        {/*    className='svg-icon svg-icon-1 me-2 text-center cursor-pointer mt-2'><img width={30} src="/icon/money-bill-transfer.svg" alt="bell"/></span>*/}
                    </div>
                },
                id: 'Paiement',
                accessor: 'tabPaiements',
                minWidth: 180,
                maxWidth: 180,
                noWrap: true,
                displayFlex: true,
                Cell: ({row}) => {
                    let result = [];

                    row.original.tabPaiements.forEach((value, index) => {
                        if (value.actif) {
                            result.push(<span>{value.libelle}</span>)
                            //si le paiement est confimé
                            if ((value.id == 0 && !row?.original?.idPersonneAccompagnateur) || (value.id == 0 && row?.original?.idPersonneAccompagnateur && row?.original?.paieSaPart)) {
                                result.push(<span
                                    style={{fill: "#211f1c"}}
                                    title="Envoyer les justificatifs de paiement"
                                    onClick={() => {
                                        sendJustificatifsPaiement.mutate(row.original.id)
                                    }} className='svg-icon svg-icon-2x m-0 cursor-pointer'><img width={25}
                                                                                                src="/icon/envelope-open-dollar.svg"
                                                                                                alt="bell"/></span>)
                            } else if (row?.original?.idPersonneAccompagnateur && !row?.original?.paieSaPart) {
                                result = "Pris en charge par l'accompagnant"
                            }
                        }
                    });

                    return result
                },
                Footer: info => {
                    return <Select2

                        data={tabPaiementSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre paiement'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdEtatPaiement(selected)
                        }}/>
                },
            },
            {
                Header: 'Infos complémentaires',
                accessor: 'informationComplementaireReponse',
                minWidth: 230,
                Cell: ({row}) => {
                    if (row.original.informationComplementaireReponse) {
                        return <>
                            <Button
                                onClick={() => {
                                    setShowInformationComplementaireReponse({
                                        ...showInformationComplementaireReponse,
                                        show: true,
                                        data: row.original.informationComplementaireReponse
                                    })
                                }}
                                variant={"primary"}>
                                Voir les informations <i className="fa fa-eye"/>
                            </Button>
                        </>
                    } else {
                        return "";
                    }
                }
            },
            {
                Header: 'Option choisie',
                id: 'optionChoisie',
                accessor: 'optionParticipation',
                minWidth: 200,
                Cell: ({row}) => {
                    let tmpData = [...data];
                    if (row.original.tabPaiements[1].actif || row.original.tabPaiements[4].actif || row.original.tabPaiements[3].actif){
                        if (!tmpData.find(item => item.id == row.original.optionParticipation?.id)){
                            tmpData.push({
                                id: row.original.optionParticipation?.id,
                                text: `${row.original.optionParticipation.designation} -- ${row.original.optionParticipation.montant} €TTC`,
                            })
                        }
                    }
                    const tabOptions = <Select2 solid
                                                data={tmpData}
                                                disabled={true}
                                                selected={row.original.optionParticipation.id != null ? [row.original.optionParticipation.id.toString()] : null}
                                                placeholder='Sélectionner un expéditeur'
                                                onChange={(selected) => {
                                                    selected = selected?.at(0);
                                                    const res = convertTabOptionsParticipation.find(
                                                        exp => exp.id.toString() === selected
                                                    );
                                                    row.original.optionParticipation = res;
                                                    // correspondant.mutateAsync(row.original)
                                                    queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants'])
                                                    snapAuth.websocket.send("invitation")
                                                    snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
                                                    snapAuth.websocket.send("correspondants")
                                                }}
                    />

                    const tabOptionsDisabled = <Select2 solid
                                                        disabled={true}
                                                        data={tmpData}
                                                        selected={row.original.optionParticipation.id != null ? [row.original.optionParticipation.id.toString()] : null}
                                                        placeholder='Sélectionner un expéditeur'
                                                        onChange={(selected) => {
                                                            selected = selected?.at(0);
                                                            const res = convertTabOptionsParticipation.find(
                                                                exp => exp.id.toString() === selected
                                                            );
                                                            row.original.optionParticipation = res;
                                                            // correspondant.mutateAsync(row.original)
                                                            queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants'])
                                                            snapAuth.websocket.send("invitation")
                                                            snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
                                                            snapAuth.websocket.send("correspondants")
                                                        }}
                    />

                    return row.original.tabPaiements[1].actif ? tabOptionsDisabled : tabOptions
                },
                Footer: info => {
                    return <Select2
                        data={data}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre option'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdOptionParticipation(selected)
                        }}/>
                },
            },
            {
                Header: 'Accompagnants',
                id: 'idTabAccompagnants',
                accessor: 'tabAccompagnants.destinataire.personne.prenomNom',
                minWidth: 200,
                Cell: ({row}) => {
                    let tabAccompagnants = "";
                    if (row.original.tabAccompagnants) {
                        tabAccompagnants = (Object.values(row.original.tabAccompagnants)).map((value, index) =>
                            <><a key={index} target="_blank" className={`color-primary invites${row.original.id}`}
                                 href={"mailto:" + value.destinataire?.personne?.email}
                                 rel="noreferrer">{value.destinataire?.personne?.prenom} {value.destinataire?.personne?.nom}<br/></a> </>
                        )
                    }

                    let result = "";

                    if (tabAccompagnants !== "" && count(Object.values(row.original.tabAccompagnants)) > 1) {
                        result =
                            <div className="d-flex justify-content-center"><Button id={"showInvite" + row.original.id}
                                                                                   onClick={() => {
                                                                                       if ($(".invites" + row.original.id).hasClass("d-none")) {
                                                                                           $(".invites" + row.original.id).removeClass("d-none");
                                                                                           $("#showInvite" + row.original.id).text("Cacher")
                                                                                       } else {
                                                                                           $(".invites" + row.original.id).addClass("d-none");
                                                                                           $("#showInvite" + row.original.id).text("Voir")
                                                                                       }
                                                                                   }
                                                                                   }>Voir</Button></div>
                        $(".invites" + row.original.id).addClass("d-none");
                    }

                    return <span>{tabAccompagnants} <br/> {result} </span>;

                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNomTabAccompagnants(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher accompagnant'
                    />
                },
            },
            {
                accessor: 'destinataire.personne.tabProfils.id',
                hidden: true,
                searchOperator: "="
            },
        ]


        let tmpChampsPerso = champsPerso.data?.data.map(exp => {
            if (exp?.parametre?.isAfficheSuivi){
                return  {
                    Header: exp?.libelle,
                    id: exp?.libelle+exp.id,
                    minWidth: 100,
                    Cell:({value, row}) => {
                        if(row?.original?.destinataire?.personne?.tabChampsPersonnalisesAnnuaire && row?.original?.destinataire?.personne?.tabChampsPersonnalisesAnnuaire[exp.id]){
                            return row?.original?.destinataire?.personne?.tabChampsPersonnalisesAnnuaire[exp.id].valeur;
                        }else {
                            return "";
                        }
                    },
                }
            }else {
                return null
            }
        });
        if (tmpChampsPerso){
            tmpChampsPerso = tmpChampsPerso.filter(e => e != null)
            return [
                ...tmp.slice(0, 11),
                ...tmpChampsPerso,
                ...tmp.slice(11),
            ]
        }else {
            return tmp;
        }
    }, [data, contactsCheckboxes]);
    const contactsColumns = useMemo(() => [
        {
            Header: () => <div className='my-2'>
                <Form.Check
                    className='form-check form-check-custom'
                    checked={contactsCheckboxes.isSelectingAll}
                    onChange={() => contactsCheckboxes.toggleSelectAll()}
                />
            </div>,
            id: 'selector',
            Cell: ({ row }) => <Form.Check
                className='form-check form-check-custom'
                checked={contactsCheckboxes.isItemChecked(row.original.personne.id)}
                onChange={() => contactsCheckboxes.toggleItem(row.original.personne.id)}
            />,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) => <div className="d-flex justify-content-center">
                <a
                onClick={() => annuaireProxy.showPersonne(row.original.personne, false, "personne", true)}
                className='svg-icon svg-icon-1 cursor-pointer'><Art005 /></a></div>,

            width: 60,
        },
        {
            Header: 'Id',
            accessor: 'personne.id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: 'Prenom',
            accessor: 'personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'personne.nom',
        },
        {
            Header: 'prenomNom',
            accessor: 'personne.prenomNom',
            hidden: true
        },
        {
            Header: 'nomPrenom',
            accessor: 'personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'Email',
            accessor: 'personne.email',
            width: 250,
        },
        {
            Header: 'Téléphones',
            accessor: 'personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.personne.tel1 ? row.original.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.personne.tel2 ? row.original.personne.tel2 : "")
                return result;
            },
        },
        {
            Header: 'Entreprises',
            accessor: 'tabEntreprises.nom',
            width: 300,
            Cell: ({ row }) => {
                if (row.original?.tabEntreprises == null) return '';
                return Object.values(row.original.tabEntreprises).map(e => e.nom).join(', ');
            },
        },
        {
            Header: 'Fonction',
            accessor: 'personne.fonction',
        },
        {
            accessor: 'personne.tabProfils.id',
            hidden: true,
            searchOperator: "="
        },
    ], [contactsCheckboxes]);
    const destinatairesColumns = useMemo(() => [
        {
            Header: () => <div className='my-2'>
                <Form.Check
                    className='form-check form-check-custom'
                    checked={destinatairesCheckboxes.isSelectingAll}
                    onChange={() => destinatairesCheckboxes.toggleSelectAll()}
                />
            </div>,
            id: 'selector',
            Cell: ({ row }) => <Form.Check
                className='form-check form-check-custom'
                checked={destinatairesCheckboxes.isItemChecked(row.original.participant.personne.id)}
                onChange={() => destinatairesCheckboxes.toggleItem(row.original.participant.personne.id)}
            />,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) => <div className="d-flex justify-content-center">
                <a
                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                    className='svg-icon svg-icon-1 cursor-pointer'><Art005 /></a></div>,

            width: 60,
        },
        {
            Header: 'Id',
            accessor: 'participant.personne.id',
            width: 80,
        },
        {
            Header: 'Prenom',
            accessor: 'participant.personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'participant.personne.nom',
        },
        {
            Header: 'Email',
            accessor: 'participant.personne.email',
            width: 250,
        },
        {
            Header: 'Téléphones',
            accessor: 'participant.personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                return result;
            }
        },
    ], [destinatairesCheckboxes]);
    const inscrireDestinatairesFilters = useDatatableColumns({
        columns: contactsColumns,
        colSearch,
        search: searchContact,
    });
    const inscrireDestinatairesByInvitationFilters = useDatatableColumns({
        columns: contactsColumnsByInvitation,
        colSearch: colSearchByInvitation,
        search: searchContact,
    });
    const inscrireDestinataires = useInscrireParticipant({
        baseRoute: baseRoute,
        id: id,
        idInvitation:(speedSnap?.speedBusiness?.idInvitation && voirDestinataires) ? speedSnap?.speedBusiness?.idInvitation : false,
        filters: (speedSnap?.speedBusiness?.idInvitation && voirDestinataires) ? inscrireDestinatairesByInvitationFilters : inscrireDestinatairesFilters,
        onSuccess: (data) => {
            if (data?.code == 266){
                BtSwal.fire("", data.message, "warning");
            }
            setUpdate(update+1);
            contactsCheckboxes.reset();
            scrollToDestinataires();
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    const desinscrireDestinatairesFilters = useDatatableColumns({
        columns: destinatairesColumns,
        search: searchDestinataire,
    });
    const desinscrireDestinataires = useDesinscrireParticipant({
        baseRoute: baseRoute,
        id: id,
        filters: desinscrireDestinatairesFilters,
        onSuccess: (data) => {
            destinatairesCheckboxes.reset();
            setUpdate(update+1);
            setValue('searchDestinataire', '');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    function addToDestinataires() {
        const idPersonnes = contactsCheckboxes.getIds();
        if (idPersonnes.length == 0){
            BtSwal.fire({
                heightAuto: false,
                title: `Êtes-vous sur de vouloirs rajouter tous les contacts de cette catégorie ?`,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
                didClose: () => scrollToDestinataires()
            }).then((result) => {
                if (result.isConfirmed){
                    inscrireDestinataires.mutate({
                        idPersonnes,
                        idProfil: selectedProfil?.id,
                        isInclusif: !contactsCheckboxes.isSelectingAll,
                    });
                    toast.success("Tous les contacts de la catégorie ont été rajoutés.")
                } else if (result.isDenied) {
                    toast.success("L'ajout annulé.")
                }
            })
        }else {
            inscrireDestinataires.mutate({
                idPersonnes,
                idProfil: selectedProfil?.id,
                isInclusif: !contactsCheckboxes.isSelectingAll,
            });
        }
    }
    function removeDestinataires() {
        const idPersonnes = destinatairesCheckboxes.getIds();
        if (idPersonnes.length == 0){
            BtSwal.fire({
                title: `Êtes-vous sur de vouloirs supprimer tous les contacts ?`,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
                didClose: () => scrollToDestinataires()
            }).then((result) => {
                if (result.isConfirmed){
                    desinscrireDestinataires.mutate({
                        idPersonnes,
                        isInclusif: !destinatairesCheckboxes.isSelectingAll,
                    });
                    toast.success("Tous les contacts ont été supprimés.")
                } else if (result.isDenied) {
                    toast.success("La suppression a été annulé.")
                }
            })
        }else {
            BtSwal.fire({
                title: `Êtes-vous sur de vouloirs supprimer les contacts sélectionnés ?`,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
                didClose: () => scrollToDestinataires()
            }).then((result) => {
                if (result.isConfirmed){
                    desinscrireDestinataires.mutate({
                        idPersonnes,
                        isInclusif: !destinatairesCheckboxes.isSelectingAll,
                    });
                    toast.success("Les contacts sélectionnés ont été supprimés.")
                } else if (result.isDenied) {
                    toast.success("La suppression a été annulé.")
                }
            })
        }
    }
    const invitation = useGetInvitationById(speedSnap.speedBusiness?.idInvitation);
    useEffect(() => {
        if (invitation?.data){
            invitationsProxy.invitation = invitation?.data;
        }
    }, [invitation]);
    const isLoading = inscrireDestinataires.isLoading || desinscrireDestinataires.isLoading || invitation?.isLoading;

    return <BlockUI loading={isLoading}>
        <Row ref={contactSectionRef}>
            <Col xs={2} className=''>
                {speedSnap?.speedBusiness?.idInvitation &&
                    <div className="d-flex justify-content-center mb-2">
                        {voirDestinataires ?
                            <Button onClick={() => {
                                setVoirDestinataires(false)
                            }}>
                                Voir tout les contacts
                            </Button>
                            :
                            <Button onClick={() => {
                                setVoirDestinataires(true)
                            }}>
                                Voir les destinataires de l'invitation
                            </Button>
                        }
                </div>}
                {onlyPartenaireProfil ? <ProfilPartenaireFilter selected={selectedProfil} onChange={setSelectedProfil} /> :<ProfilSelection selected={selectedProfil} onChange={setSelectedProfil}/>}
            </Col>
            <Col xs={10}>
                <Card className='card-flush'>
                    <Card.Body>
                        <div className='d-flex align-items-end'>
                            <div>
                            {!speedSnap?.speedBusiness?.idInvitation || !voirDestinataires ?
                                <h4 className=''>Contacts</h4> :
                                <h4 className='' style={{
                                    width: "fit-content"
                                }}>Destinataire de l'invitation -- {invitation?.data?.sujet}</h4>}
                                <SearchBar
                                    solid
                                    {...register('searchContact')}
                                    onClear={searchContact?.length > 0 ? () => {
                                        setValue('searchContact', '');
                                    } : null}
                                />
                            </div>
                            {speedSnap?.speedBusiness?.idInvitation && voirDestinataires &&
                                <Button style={{
                                    height:"fit-content"
                                }}  className="ms-3"
                                        onClick={() => {
                                            speedBusinessProxy.showFiltresParticipants = true
                                        }}
                                >
                                    <i className="fa fa-filter"></i>Filtres
                                </Button>}
                            <div className="ms-auto">
                                {!speedSnap?.speedBusiness?.idInvitation || !voirDestinataires && <Button
                                    variant='primary'
                                    className='me-2'
                                    onClick={() => {
                                        annuaireProxy.showPersonne(null, false, "personne", true)
                                    }}
                                    // onClick={addToDestinataires}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                                    Ajouter un contact
                                </Button>}
                                <Button
                                    variant='secondary'
                                    className='ms-auto'
                                    onClick={addToDestinataires}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                                    Ajouter la sélection aux participants
                                </Button>
                            </div>
                            <Button variant='danger' className='btn-icon ms-2 pulse' onClick={scrollToDestinataires}>
                                <FontAwesomeIcon icon={faArrowDown} />
                                <span className='pulse-ring' />
                            </Button>
                        </div>
                        {speedSnap?.speedBusiness?.idInvitation && invitation?.data && voirDestinataires ?
                            <MetaTableDestinataireByInvitation
                                selectedProfil={selectedProfil}
                                contactsCheckboxes={contactsCheckboxes}
                                columns={contactsColumnsByInvitation}
                                debouncedSearch={debouncedSearchContact}
                                stateIdEtatPaiement={stateIdEtatPaiement}
                                stateIdTabInvitationFilter={stateIdTabInvitationFilter}
                                stateIdOptionParticipation={stateIdOptionParticipation}
                                colSearch={colSearchByInvitation}
                                alertInfo={<Alert style={{
                                    width: "fit-content"
                                }}>
                                    {nbParticipants} participants inscrit sur {speedSnap?.speedBusiness?.nombreTablesChoisies*speedSnap?.speedBusiness?.nombrePersonnesParTableChoisies} participants maximum
                                </Alert>}
                            />
                            : <><MetaTable
                            className='mt-5'
                            height={450}
                            url='destinataire'
                            keys={['destinataire']}
                            columns={contactsColumns}
                            colSearch={colSearch}
                            search={debouncedSearchContact}
                        />
                                <Alert style={{
                                    width: "fit-content"
                                }}>
                                    {nbParticipants} participants inscrit sur {speedSnap?.speedBusiness?.nombreTablesChoisies*speedSnap?.speedBusiness?.nombrePersonnesParTableChoisies} participants maximum
                                </Alert>
                            </>

                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <div className='separator my-10' />
        <Card className='card-flush' ref={destinataireSectionRef}>
            <Card.Body>
                <div className='d-flex align-items-end'>
                    <div>
                        <h4 className=''>Participants</h4>
                        <SearchBar
                            solid
                            {...register('searchDestinataire')}
                            onClear={searchDestinataire?.length > 0 ? () => {
                                setValue('searchDestinataire', '');
                            } : null}
                        />
                    </div>
                    <Button
                        variant='secondary'
                        className='ms-auto'
                        onClick={removeDestinataires}
                    >
                        <FontAwesomeIcon icon={faTrash} className='me-2' />
                        Enlever les participants séléctionnés
                    </Button>
                    <Button variant='danger' className='btn-icon ms-2' onClick={scrollToContacts}>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </Button>
                </div>
                <MetaTable
                    className='mt-5'
                    height={450}
                    url={`${baseRoute}/${id}/participants`}
                    keys={[baseRoute, id, 'participants']}
                    columns={destinatairesColumns}
                    search={debouncedSearchDestinataire}
                />
            </Card.Body>
        </Card>
    </BlockUI>;
}

export default DestinatairesStep;