import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import React, {useMemo, useState} from "react";
import moment from "moment";
import annuaireProxy from "../../../../proxies/annuaire";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import {Button, Card, Dropdown} from "react-bootstrap";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import Flatpickr from "react-flatpickr";
import {French} from "flatpickr/dist/l10n/fr";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWrench} from "@fortawesome/free-solid-svg-icons";
import useSendMailMissionMaitreDuTempsForOneParticiapant
    from "../../../../requests/communication/speedBusiness/useSendMailMissionMaitreDuTempsForOneParticiapant";
import useSendMailMissionMaitreDuTemps from "../../../../requests/communication/speedBusiness/useSendMailMissionMaitreDuTemps";

export default function EmailMissionMaitreDuTemps({baseRoute, id}){
    const { register, watch, setValue } = useForm();
    const searchDestinataire = watch('searchDestinataire');
    const [debouncedSearchDestinataire] = useDebounce(searchDestinataire, 300);
    const sendissionForOneParticipant = useSendMailMissionMaitreDuTempsForOneParticiapant();
    const speedSnap = useSnapshot(speedBusinessProxy);
    const sendMailMission = useSendMailMissionMaitreDuTemps();
    const colSearch = useMemo(() => {
        return {
            'isMaitreDuTemps': 0,
            'isMaitreDuTempsSuppleant': 0,
        };
    }, []);
    const [showProgammerMail, setShowProgrammerMail] = useState(false);
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY à HH:mm"));
    const destinatairesColumns = useMemo(() => [
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) =>
                <Dropdown>
                    <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                        <FontAwesomeIcon color={"black"} icon={faWrench} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                        <div className='menu-item px-3'>
                            <Dropdown.Item
                                onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                            >
                                <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                                Modifier
                            </Dropdown.Item>
                            <Dropdown.Item
                                title={"envoyer mail de rappel"}
                                onClick={() => {
                                    BtSwal.fire({
                                        title: `<b>Êtes-vous sûr de vouloir envoyer le mail de mission à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                        showDenyButton: true,
                                        confirmButtonText: `Oui`,
                                        denyButtonText: `Annuler`,
                                        icon: 'info',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            sendissionForOneParticipant.mutate(row?.original?.id);
                                        } else if (result.isDenied) {
                                            BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                        }
                                    })
                                }}
                            >
                               <span style={{
                               }} className='svg-icon svg-icon-1 me-2'><Gen016/> Envoyer email de mission</span>
                            </Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'participant.personne.id',
            width: 80,
            Cell: ({row}) => {
                return <span>{row?.original?.participant?.personne?.id} <i style={{
                    color: row?.original?.isMaitreDuTemps ? "gold" : "silver"
                }} className="fa fa-crown"></i></span>;
            }
        },
        {
            Header: 'Passer en maitre du temps',
            accessor: 'isMaitreDuTemps',
            searchOperator: "=",
            logic: "OR",
            hidden: true
        },
        {
            Header: 'isMaitreDuTempsSuppleant',
            accessor: 'isMaitreDuTempsSuppleant',
            searchOperator: "=",
            logic: "OR",
            hidden: true
        },
        {
            Header: 'Prenom',
            accessor: 'participant.personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'participant.personne.nom',
        },
        {
            Header: 'Email',
            accessor: 'participant.personne.email',
            width: 250,
        },
        {
            Header: 'Téléphones',
            accessor: 'participant.personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                return result;
            }
        },
    ], []);
    return <Card className='card-flush'>
        <Card.Body>
            <div className='d-flex justify-content-between'>
                <div className="m-auto">
                    <Button style={{
                        height: "fit-content"
                    }} onClick={() => {
                        BtSwal.fire({
                            title: `<b>Êtes-vous sûr de vouloir envoyer le mail de mission à tous les participants ?</b>`,
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                            icon: 'info',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                sendMailMission.mutate({
                                        id: id,
                                        type: 'emailMissionMaitreTemps',
                                        dateProgrammationEnvoi: moment().format("DD/MM/YYYY à HH:mm"),
                                });
                            } else if (result.isDenied) {
                                BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                            }
                        })
                    }}>Envoyer le mail de mission a tout les maitres du
                        temps {((speedSnap?.speedBusiness?.nombreTablesChoisies + 1) == speedSnap?.speedBusiness?.nombreTours) ? "et maitres du temps suppléant" : ""}</Button>

                    <div className="mt-2 d-flex justify-content-center align-items-center">
                        <Button className="me-2" style={{

                            height: "fit-content"
                        }}
                        onClick={() => {
                            setShowProgrammerMail(!showProgammerMail)
                        }}
                        >
                            Programmer l'envoi
                        </Button>
                        {showProgammerMail ?
                            <div style={{
                                width: "fit-content",
                                margin: "auto"
                            }}>
                                <Flatpickr
                                    className={"form-control mt-2"}
                                    display={"hidden"}
                                    defaultValue={dateEnvoi}
                                    onChange={date => {
                                        setDateEnvoi(moment(date[0]).format("DD/MM/YYYY à HH:mm"));
                                    }}
                                    options={{
                                        locale: French,
                                        dateFormat: 'd/m/Y à H:i',
                                        enableTime: true,
                                    }}
                                />
                                <Button onClick={() => {
                                    BtSwal.fire({
                                        title: `<b>Êtes-vous sûr de vouloir envoyer le mail de mission à tous les participants ?</b>`,
                                        showDenyButton: true,
                                        confirmButtonText: `Oui`,
                                        denyButtonText: `Annuler`,
                                        icon: 'info',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            sendMailMission.mutate({
                                                id: id,
                                                type: 'emailMissionMaitreTemps',
                                                dateProgrammationEnvoi: dateEnvoi,
                                            });
                                        } else if (result.isDenied) {
                                            BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                        }
                                    })
                                }} className="mt-2 m-auto d-block">Valider</Button>
                            </div> :
                            ""
                        }

                    </div>
                </div>
            </div>
            <div style={{
                margin: "auto",
                maxWidth: 1100
            }}>
                <div style={{
                    height: "fit-content",
                    width: 400
                }}>
                    <h4>Participants</h4>
                    <SearchBar
                        solid
                        {...register('searchDestinataire')}
                        onClear={searchDestinataire?.length > 0 ? () => {
                            setValue('searchDestinataire', '');
                        } : null}
                    />
                    <div>
                        <div>
                            <i style={{
                                color: "gold"
                            }} className="fa fa-crown me-2"></i>Maitre du temps
                        </div>
                        <div>
                            <i
                                style={{
                                    color: "silver"
                                }}
                                className="fa fa-crown me-2"></i>Maitre du temps suppléant
                        </div>
                    </div>
                </div>
                <MetaTable
                    className='mt-5'
                    height={450}
                    url={`${baseRoute}/${id}/participants`}
                    keys={[baseRoute, id, 'participants', 'mission']}
                    columns={destinatairesColumns}
                    colSearch={colSearch}
                    search={debouncedSearchDestinataire}
                />
            </div>
        </Card.Body>
    </Card>;
}