import {useEffect, useMemo, useState} from "react";
import {useController} from "react-hook-form";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise"
import useGetTva from "../../../requests/parametresPaiement/tva/useGetTva";
import {getLoadOptionTva} from "../../../requests/selectionRequests/tauxTva";

function TauxTvaSelection({ control, name, rules, init }) {
    const tauxTva = useGetTva("", 15);
    const { field } = useController({ control, name, rules });
    const data = useMemo(() => {
        return tauxTva.data?.data?.map(exp => ({
            id: exp.id,
            title: `${exp.valeur}% ${exp.description ? "("+exp.description+")" : ""}`,
        }));
    }, [tauxTva.data]);
    const [selectedValue, setSelectedValue] = useState((field?.value?.id != null || typeof field?.value?.id != "undefined") ? {id:field.value.id,title:(field?.value?.valeur != null ? field.value.valeur+"%" : field.value.title)+" "+(field.value.description ? "("+field.value.description+")" : "")+" "} : null);
    useEffect(() => {
        if (field.value && field?.value?.id && !selectedValue){
            setSelectedValue( {id:field.value.id,title:field.value.valeur+"% "+(field.value.description ? "("+field.value.description+")" : "" )+" "} )
        }
    }, [init])
    const handleChange = value => {
        setSelectedValue(value);
    }
    const loadOptions = getLoadOptionTva();
    const debouncedLoadOptions = debounce(loadOptions, 500)

    return <AsyncSelect
        placeholder={"Choisir un taux de tva"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? 'var(--primary-color)' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'var(--primary-color-light)' : "var(--primary-color)",
                }
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#f8f6f2",
                borderRadius : ".95rem",
                color: '#716d66',
            }),
        }}
        cacheOptions
        defaultOptions={data}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        loadOptions={debouncedLoadOptions}
        onChange={ (selected) => {
            handleChange(selected)
            field.onChange(selected)
        }}
    />
}

export default TauxTvaSelection;