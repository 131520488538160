import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./CountDownCircleTimer.css";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";

const renderTime = ({ remainingTime }) => {
    return (
        <div className="timer-CDCT">
            <div className="value-CDCT-light">{remainingTime}</div>
        </div>
    );
};

function CountDownCircleTimerForTurn({color = "--primary-color", setCurrentTime, isPlaying = true, duration = 10, valueChangeRemainingTimeLight = false}) {
    const [key, setKey] = useState(0);
    const [initialRemainingTime, setInitialRemainingTime] = useState(valueChangeRemainingTimeLight ? valueChangeRemainingTimeLight : duration);
    useEffect(() => {
        if (valueChangeRemainingTimeLight){
            setInitialRemainingTime(valueChangeRemainingTimeLight);
            setKey(uuidv4())
        }else {
            setInitialRemainingTime(duration)
            setKey(uuidv4())
        }
    }, [valueChangeRemainingTimeLight, duration]);
    return (
        <div className="d-none justify-content-center align-items-center ms-3">
            <div className="timer-wrapper-CDCT position-relative">
                <CountdownCircleTimer
                    key={key}
                    initialRemainingTime={initialRemainingTime ? initialRemainingTime : duration}
                    trailStrokeWidth={3}
                    strokeWidth={3}
                    size={30}
                    isPlaying={isPlaying}
                    duration={duration}
                    colors={["var("+color+")"]}
                    onUpdate={(newTime) => {
                        setCurrentTime(newTime)
                    }}
                >
                </CountdownCircleTimer>
            </div>
        </div>
    );
}

export default CountDownCircleTimerForTurn;
