import {Button, Card, Dropdown, Modal} from "react-bootstrap";
import SearchBar from "../../components/SearchBar/SearchBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlus, faTimes, faWrench} from "@fortawesome/free-solid-svg-icons";
import {useMemo, useState} from "react";
import {ReactComponent as Art005} from "@assets/icons/art005.svg";
import { ReactComponent as Gen027 } from "@assets/icons/gen027.svg";
import {ReactComponent as JourJIcon} from "@assets/icons/jourJIcon.svg";
import MetaTable from "../../components/MetaTable/MetaTable";
import {useDebounce} from "use-debounce";
import {ReactComponent as UserCrow} from "../../assets/icons/user-group-crown.svg";
import {ReactComponent as ListCheck} from "../../assets/icons/list-check-solid.svg";
import {ReactComponent as FileExport} from "../../assets/icons/file-export-solid.svg";
import {useForm} from "react-hook-form";
import speedBusinessProxy from "../../proxies/speedBusiness";
import EditModal from "./EditModal/EditModal";
import ExportParTable from "../../views/SpeedBusinessView/Exports/ExportParTable";
import useGetParticipants from "../../requests/communication/speedBusiness/useGetParticipants";
import useGetGenererParTable from "../../requests/communication/speedBusiness/useGetGenererParTable";
import useGetGenererParParticipant from "../../requests/communication/speedBusiness/useGetGenererParParticipant";
import ExportParParticipant from "../../views/SpeedBusinessView/Exports/ExportParParticipant";
import JourJModal from "./JourJModal/JourJModal";
import useDeleteSpeed from "../../requests/communication/speedBusiness/useDeleteSpeed";
import {BtSwal} from "../../utils/alerts/sweetAlert";
import useGetMailMaitreDuTempsRencontre
    from "../../requests/parametres/emailMaitreDuTempsRencontre/useGetMailMaitreDuTempsRencontre";
import useGetMailRappelRencontre from "../../requests/parametres/emailRappelRencontre/useGetMailRappelRencontre";
import useGetMailTableRencontre from "../../requests/parametres/emailTableRencontre/useGetMailTableRencontre";
import LoadingView from "../../views/LoadingView/LoadingView";
import EmailMaitreDuTempsRencontreSetting
    from "../../views/ParametresView/EmailMaitreDuTempsRencontre/EmailMaitreDuTempsRencontre";
import EmailRappelRencontreSetting
    from "../../views/ParametresView/EmailRappelRencontreSettings/EmailRappelRencontreSettings";
import EmailTableRencontreSetting
    from "../../views/ParametresView/EmailTableRencontreSettings/EmailTableRencontreSettings";
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import useGetParticipantsSansMaitre from "../../requests/communication/speedBusiness/useGetParticipantsSansMaitre";
import useGetParticipantsMaitre from "../../requests/communication/speedBusiness/useGetParticipantsMaitre";
import useGetParticipantsSuppleant from "../../requests/communication/speedBusiness/useGetParticipantsSuppleant";
import MaitreDuTempsModal from "../../views/SpeedBusinessView/MaitreDuTempsModal/MaitreDuTempsModal";
import LancementSpeedBusinessModal
    from "../../views/SpeedBusinessView/LancementSpeedBusinessModal/LancementSpeedBusinessModal";
import CheckMaitreDuTempsTutoAndSendMailModal
    from "../../views/SpeedBusinessView/CheckMaitreDuTempsTutoAndSendMailModal/CheckMaitreDuTempsTutoAndSendMailModal";
import PresencesModal from "../../views/SpeedBusinessView/PresencesModal/PresencesModal";
import EmailAndSmsPassageModal from "../../views/SpeedBusinessView/EmailAndSmsPassageModal/EmailAndSmsPassageModal";
import LastCheckSuppleantAndGoModal
    from "../../views/SpeedBusinessView/LastCheckSuppleantAndGoModal/LastCheckSuppleantAndGoModal";
import GestionReceptionnisteModal
    from "../../views/SpeedBusinessView/GestionReceptionnisteModal/GestionReceptionnisteModal";


export default function SpeedBusinessView(){
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const colSearch =  {
    };
    const deleteSpeed = useDeleteSpeed();
    const [searchFilter] = useDebounce(search, 300);
    const mailMaitreDuTempsRencontre = useGetMailMaitreDuTempsRencontre();
    const mailRappelRencontre = useGetMailRappelRencontre();
    const mailTableRencontre = useGetMailTableRencontre();
    const [showModalSettings, setShowModalSettings] = useState(false);
    const [currentComponentSettings, setCurrentComponentSettings] = useState(null);
    const participantsSansMaitre = useGetParticipantsSansMaitre();
    const participantsMaitreDuTemps = useGetParticipantsMaitre();
    const participantsMaitreDuTempsSuppleant = useGetParticipantsSuppleant();
    const genererParTable = useGetGenererParTable();
    const genererParParticipant = useGetGenererParParticipant();
    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => <Dropdown>
                <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon color={"black"} icon={faWrench} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                speedBusinessProxy.showEditSpeedBusiness(row.original)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                            Modifier
                        </Dropdown.Item>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                speedBusinessProxy.showMaitreDuTemps(row.original)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><UserCrow/></span>
                            Gestion des maitres du temps
                        </Dropdown.Item>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                speedBusinessProxy.showGestionReceptionniste(row.original)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><ListCheck /></span>
                            Gestion réceptionnistes
                        </Dropdown.Item>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                speedBusinessProxy.showLancementSpeedBusiness(row.original)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><JourJIcon /></span>
                            Lancer la rencontre
                        </Dropdown.Item>
                        {/*<Dropdown.Item*/}
                        {/*    className='menu-link px-3'*/}
                        {/*    onClick={() => {*/}
                        {/*        speedBusinessProxy.showJourJ(row.original)*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <span className='svg-icon svg-icon-1 me-2'><JourJIcon /></span>*/}
                        {/*    Le jour J*/}
                        {/*</Dropdown.Item>*/}
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={async () => {
                                const result = await BtSwal.fire({
                                    title: 'Voulez vous prendre en compte les présences ?',
                                    icon: 'question',
                                    showDenyButton: true,
                                    showCancelButton: false,
                                    confirmButtonText: 'Oui',
                                    denyButtonText: 'Non',
                                    cancelButtonText: 'Annuler',
                                    allowEscapeKey: false,
                                    allowOutsideClick: false
                                });

                                ExportParTable({id :row?.original?.id,
                                    nomTables:row?.original?.tabNomsTables,
                                    participants: participantsSansMaitre,
                                    participantsMaitre:participantsMaitreDuTemps,
                                    participantsSupplant:participantsMaitreDuTempsSuppleant,
                                    nbTables:row?.original.nombreTablesChoisies,
                                    nbTours:row?.original?.nombreTours,
                                    genererParTable,
                                    utiliserParticipant:result.isConfirmed
                                });
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><FileExport /></span>
                            Exporter génération par tables
                        </Dropdown.Item>
                        <Dropdown.Item
                        className='menu-link px-3'
                        onClick={async () => {
                            const result = await BtSwal.fire({
                                title: 'Voulez vous prendre en compte les présences ?',
                                icon: 'question',
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonText: 'Oui',
                                denyButtonText: 'Non',
                                cancelButtonText: 'Annuler',
                                allowEscapeKey: false,
                                allowOutsideClick: false
                            });

                            ExportParParticipant({id :row?.original?.id,
                                nomTables:row?.original?.tabNomsTables,
                                participants:participantsSansMaitre,
                                participantsMaitre:participantsMaitreDuTemps,
                                participantsSupplant:participantsMaitreDuTempsSuppleant,
                                nbTables:row?.original.nombreTablesChoisies,
                                nbTours:row?.original?.nombreTours,
                                genererParParticipant,
                                utiliserParticipant:result.isConfirmed
                            });
                        }}
                    >
                        <span className='svg-icon svg-icon-1 me-2'><FileExport /></span>
                        Exporter génération par participants
                    </Dropdown.Item>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                BtSwal.fire({
                                    title: 'Êtes vous sur de vouloir supprimer la rencontre ?',
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        deleteSpeed.mutate(row.original.id);
                                        BtSwal.fire('La rencontre a été supprimé !', '', 'success')
                                    } else if (result.isDenied) {
                                        BtSwal.fire('La rencontre n\'a pas été supprimé', '', 'info')
                                    }
                                })

                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen027 /></span>
                            Supprimer
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 70,
            maxWidth: 70,
            Cell: ({ value, row }) => {
                return value
            },
        },
        {
            Header: 'Nom',
            accessor: 'nom',
            minWidth: 300,
            noWrap: true
        }
    ], []);

    if (mailMaitreDuTempsRencontre.isLoading || mailTableRencontre.isLoading || mailRappelRencontre.isLoading){
        return <LoadingView/>
    }
    if (paramsIsOk() !== true){
        return  <Card style={{height: "80vh"}}><div className="table-responsive">
            <h1 className="text-center mt-3
            ">Paramètres obligatoire la génération d'une rencontre</h1>
            <table className="m-auto table mt-4 table-row-dashed w-800px table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                    <th className="w-400px rounded-start"></th>
                    <th className="w-140px">Paramétré</th>
                </tr>
                </thead>
                <tbody className="border-bottom border-dashed">
                {paramsIsOk()}
                </tbody>
            </table>
            <Modal
                show={showModalSettings}
                onHide={() => setShowModalSettings(false)}
                size='lg'
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Paramètres rencontre</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentComponentSettings}
                </Modal.Body>
            </Modal>
        </div></Card>
    }else {
        return <Card>
            <Card.Body>
                <div className='d-flex flex-stack flex-wrap'>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <div className='mb-5'>
                        <Button variant='secondary' onClick={() => {
                            speedBusinessProxy.showEditSpeedBusiness(null);
                        }}>
                            <FontAwesomeIcon icon={faPlus} className='me-2' />
                            Générer les rencontres
                        </Button>
                    </div>
                </div>
                <div>
                    <MetaTable
                        className='mt-5'
                        height={500}
                        url='rencontreSpeedBusiness'
                        keys={['rencontreSpeedBusiness']}
                        columns={columns}
                        search={searchFilter}
                        colSearch={colSearch}
                    />
                </div>
                <EditModal/>
                <JourJModal/>
                <MaitreDuTempsModal/>
                <LancementSpeedBusinessModal/>
                <CheckMaitreDuTempsTutoAndSendMailModal/>
                <PresencesModal/>
                <EmailAndSmsPassageModal/>
                <LastCheckSuppleantAndGoModal/>
                <GestionReceptionnisteModal/>
            </Card.Body>
        </Card>;
    }

    function paramsIsOk(){
        let result = [];
        let allIsOk = true;
        //check mail maitre du temps
        if (mailMaitreDuTempsRencontre.isSuccess && (!mailMaitreDuTempsRencontre.data.corpsHtml || !mailMaitreDuTempsRencontre.data.expediteur)){
            result.push(paramPasOkLine("Mail maitre du temps", <EmailMaitreDuTempsRencontreSetting />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail maitre du temps", <EmailMaitreDuTempsRencontreSetting />))
        }
        //check mail rappel rencontre
        if (mailRappelRencontre.isSuccess && (!mailRappelRencontre.data.corpsHtml || !mailRappelRencontre.data.expediteur)){
            result.push(paramPasOkLine("Mail de rappel rencontre", <EmailRappelRencontreSetting />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail de rappel rencontre", <EmailRappelRencontreSetting />))
        }
        // check categorie
        if (mailTableRencontre.isSuccess && (!mailTableRencontre.data.corpsHtml || !mailTableRencontre.data.expediteur)){
            result.push(paramPasOkLine("Mail ordre de passage", <EmailTableRencontreSetting />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail ordre de passage", <EmailTableRencontreSetting />))
        }

        //todo check sms

        // // check expéditeur
        // if (expediteurs.isSuccess && expediteurs.data.nbLignesTotales == 0){
        //     result.push(paramPasOkLine("Exépditeurs de mail", <ExpediteurSettings />))
        //     allIsOk = false;
        // }else {
        //     result.push(paramOkLine("Expéditeurs de mail", <ExpediteurSettings />))
        // }
        return allIsOk ? allIsOk : result;
    }

    function paramOkLine(title, settingsConponent){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faCheck} color={"green"}/>
                <span
                    style={{fill: "black"}}
                    onClick={() => {
                        setCurrentComponentSettings(settingsConponent)
                        setShowModalSettings(true)
                    }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
            </td>
        </tr>
    }

    function paramPasOkLine(title, settingsConponent) {
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faTimes} color={"red"}/>
                <span
                    style={{fill: "black"}}
                    onClick={() => {
                        setCurrentComponentSettings(settingsConponent)
                        setShowModalSettings(true)
                    }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>

            </td>
        </tr>
    }

}