import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Accordion, Alert, Button, Col, Form, FormGroup, Row, Spinner} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import React, {useEffect, useRef, useState} from "react";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import useGetChampPerso from "../../../requests/annuaire/useGetChampPerso";
import useChangeChampsPerso from "../../../requests/annuaire/useChangeChampsPerso";
import useDeleteChampPerso from "../../../requests/annuaire/useDeleteChampPerso";
import TypeInvitationSelectionMultiple
    from "../../communication/TypeInvitationSelection/TypeInvitationSelectionMultiple";
import SelectChampsPersoTab from "./SelectChampsPersoTab";
import useGetChampPersonnaliseAnnuaire from "../../../requests/annuaire/useGetChampPersonnaliseAnnuaire";
import useDeleteValueChampPerso from "../../../requests/annuaire/useDeleteValueChampPerso";
import {allowAllLetterAndAccentAndSpace} from "../../../functions/patterns";
import BlockUI from "../../../components/BlockUI/BlockUI";
import parametreProxy from "../../../proxies/parametre";

function ChampsPersonnaliseSettings() {
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [debouncedFilter] = useDebounce(search, 500);
    const champsPerso = useGetChampPerso(debouncedFilter);
    const queryClient = useQueryClient();
    const [activeKey, setActiveKey] = useState();
    const [hiddenEditLines, setHiddenEditLines] = useState(false);
    const [hiddenAdd, setHiddenAdd] = useState(false);
    const deleteChampPerso = useDeleteChampPerso();
    const snapAuth = useSnapshot(auth);
    const deleteValueChampPerso = useDeleteValueChampPerso();
    const [update, setUpdate] = useState(0);
    const changeChampPerso = useChangeChampsPerso({
        onSuccess: () => {
            queryClient.invalidateQueries('champPersonnaliseAnnuaire');
            snapAuth.websocket.send("champPersonnaliseAnnuaire")
            setHiddenAdd(false)
            setActiveKey(null);
        },
        onError: (err) => {
            BtSwal.fire(err.message, '', 'error')
        }
    });
    const changeChampPersoSelectChamp = useChangeChampsPerso({
        onSuccess: () => {
            queryClient.invalidateQueries('champPersonnaliseAnnuaire');
            BtSwal.fire("Modification prise en compte", '', 'success')
        },
        onError: (err) => {
            BtSwal.fire(err.message, '', 'error')
        }
    });

    function onSave(data) {
        changeChampPerso.mutate(data.data);
        data?.deleteValuesChampPerso.forEach(e => {
            deleteValueChampPerso.mutate({
                id: data?.id,
                valeur : e
            })
        })

    }

    const wrapperRef = useRef(null);
    const [alertForSave, setAlertForSave] = useState(false);
    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey) {
            setActiveKey(null);
            setHiddenAdd(false)
            setAlertForSave(false);
        }
        else {
            setActiveKey(eventKey);
            setHiddenAdd(true)
            setAlertForSave(true);
        }
    }

    // permet de générer une alert a chaque clique en dehors du wrapper
    // useEffect(() => {
    //     /**
    //      * Alert if clicked on outside of element
    //      */
    //     function handleClickOutside(event) {
    //
    //         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //             document.removeEventListener("mousedown", handleClickOutside);
    //         }
    //         if (activeKey && alertForSave && wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.target.className.includes("swal2")){
    //             BtSwal.fire({
    //                 title: `<b>Êtes vous sur de vouloir quitter sans enregistrer ?</b>`,
    //                 showDenyButton: true,
    //                 confirmButtonText: `Oui`,
    //                 denyButtonText: `Non`,
    //                 icon: 'info',
    //             }).then((result) => {
    //                 if (result.isConfirmed) {
    //                     event.target.click()
    //                     setAlertForSave(false);
    //                 }else {
    //                     setAlertForSave(false);
    //                 }
    //             })
    //         }
    //     }
    //     if (activeKey && alertForSave){
    //         // Bind the event listener
    //         document.addEventListener("mousedown", handleClickOutside);
    //     }
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [wrapperRef, activeKey]);

    useEffect(() => {
        document?.getElementById("searchBarId")?.focus();
    }, [champsPerso.isSuccess])
    const [paActive, setPaActive] = useState(true)
    useEffect(() => {
        const updateWindowDimensions = () => {
            if ((document.querySelector(".pa") && document.querySelector(".pa").offsetWidth < 1100)){
                setPaActive(false);
            }else {
                setPaActive(true);
            }
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);
    if (champsPerso.isError)
        return <Alert variant='danger'>{champsPerso.error?.message}</Alert>;

    if (champsPerso.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    useEffect(() => {
        parametreProxy.isModifie = hiddenAdd;
    }, [hiddenAdd])


    return <Accordion style={{height: "100vh"}} ref={wrapperRef} activeKey={activeKey} className='mt-7 position-relative box pa'>
        <div className='d-flex flex-stack flex-wrap'>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <div className='d-flex'>
                <div hidden={hiddenAdd ? hiddenAdd : hiddenEditLines} className='mx-auto fw-bold'>
                    <Button
                        variant='secondary'
                        className='px-20'
                        onClick={() => {
                            toggleAccordionKey('new')
                            // setHiddenEditLines(true);
                            setUpdate(update+1);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter un champ
                    </Button>
                </div>
            </div>
        </div>
        {champsPerso.data?.data?.map(exp => {
            return <div hidden={hiddenEditLines} key={exp.id}>
                <div className='d-flex flex-wrap justify-content-start  align-content-center'>
                    <div className="d-flex align-content-center bg-light my-2 p-3 rounded align-items-center w-400px" style={{height: "fit-content"}}>
                        <div className='me-auto fw-bold'>
                            {exp.libelle}
                        </div>
                        <button
                            onClick={() => {
                                if ((document.querySelector(".pa") && document.querySelector(".pa").offsetWidth < 1100)){
                                    setPaActive(false);
                                }else {
                                    setPaActive(true);
                                }
                                if (activeKey && alertForSave) {
                                    BtSwal.fire({
                                        title: `<b>Êtes vous sur de vouloir quitter sans enregistrer ?</b>`,
                                        showDenyButton: true,
                                        confirmButtonText: `Oui`,
                                        denyButtonText: `Non`,
                                        icon: 'info',
                                        didOpen: () => {
                                            setAlertForSave(false);
                                        }
                                    }).then(function (result) {
                                        if (result.isConfirmed) {
                                            toggleAccordionKey(exp.id)
                                        }
                                    })
                                }else {
                                    toggleAccordionKey(exp.id)
                                }
                            }}
                            className='btn-sm btn btn-quaternaire p-1 ps-2 pe-2'
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </button>
                        <button
                            onClick={() => BtSwal.fire({
                                title: 'Êtes vous sur de vouloir supprimer le champ '+exp.libelle+' ?' ,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                            }).then((result) => {
                                if (result.isConfirmed){
                                    deleteChampPerso.mutate(exp.id)
                                    BtSwal.fire('Le champ a été supprimé!', '', 'success')
                                    setHiddenAdd(false)
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le champ n\'a pas été supprimé', '', 'info')
                                }
                            })}
                            className='btn-sm btn btn-secondary p-1 ps-2 pe-2'
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    <div style={{left:500, top: 50}} className={`${exp.id == activeKey && "bg-light my-2 p-3 rounded"} mw-800px ${paActive && "position-absolute"} `}>
                        <Accordion.Collapse eventKey={exp.id}>
                            <div className='mt-3'>
                                <ChampForm update={update} champPerso={exp} changeChampPerso={changeChampPerso} changeChampPersoSelectChamp={changeChampPersoSelectChamp} onSaveTab={changeChampPersoSelectChamp} setHiddenAdd={setHiddenAdd} onSave={onSave} />
                            </div>
                        </Accordion.Collapse>
                    </div>
                </div>
            </div>;
        })}
        <div style={{left:500, top: 50}} className={`mw-800px ${paActive && "position-absolute"}`}>
            <Accordion.Collapse eventKey={'new'}>
                <div className='bg-light rounded mt-3 pt-3 mx-10'>
                    <ChampForm update={update} changeChampPerso={changeChampPerso} changeChampPersoSelectChamp={changeChampPersoSelectChamp} onSave={onSave} onSaveTab={changeChampPersoSelectChamp} setHiddenEditLines={setHiddenEditLines} toggleAccordionKey={toggleAccordionKey} />
                </div>
            </Accordion.Collapse>
        </div>
    </Accordion>;
}

function ChampForm({ champPerso, onSave, onSaveTab, setHiddenEditLines, setHiddenAdd, toggleAccordionKey, update, changeChampPerso, changeChampPersoSelectChamp }) {
    const { register,handleSubmit,watch, getValues, setValue , control, reset, formState: { errors } } = useForm({
        defaultValues: champPerso ?? {
            libelle: null,
            parametre: {
                type: null,
                value: null,
                isAfficheSuivi: null,
                tabTypeIsAfficheSuivi: null
            },

        },
    });
    const valuesChampPersoAnnuaire = useGetChampPersonnaliseAnnuaire({
        onSuccess: (data) => {
            setUsedValue(data)
        },
    });
    const type = watch("parametre.type")
    const isAfficheSuivi = watch("parametre.isAfficheSuivi");
    const tabChoice = watch("parametre.tabChoice");
    const [activeChoice, setActiveChoice] = useState(null);
    const [previewChoice, setPreviewChoice] = useState(null);
    const [updateChoice, setUpdateChoice] = useState(0);
    const [usedValues, setUsedValue] = useState([]);
    const [deleteValuesChampPerso, setDeleteValuesChampPerso] = useState([]);
    useEffect(() => {
        if (champPerso?.id){
            let data = async () => {
                return await valuesChampPersoAnnuaire.mutateAsync(champPerso?.id)
            }
            data().then((result) => {
                setUsedValue(result)
            })
        }
    }, [champPerso?.id])
    useEffect(() => {
        if (tabChoice && type == "choice"){
            let tmp = (tabChoice).map((value, index) =>
                <label
                    key={index}
                    className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.text == activeChoice ? `active` : ``}`}
                    data-kt-button="true">
                    <input
                        onClick={(err) => {
                            setActiveChoice(value.text)
                        }}
                        className="btn-check" type="radio" name="method" value={value.id}/>
                    <span style={{fontSize: '10px'}}>{value.text}</span></label>
            );
            setPreviewChoice(tmp);
        }
    }, [tabChoice, updateChoice, activeChoice])

    useEffect(() => {
        reset(champPerso)
    }, [champPerso, reset, update])

    function handleSubmitSave(data) {
        if (setHiddenEditLines) {
            setHiddenEditLines(false)
        }
        if (setHiddenAdd){
            setHiddenAdd(false)
        }

        onSave({
            data:data,
            id:champPerso?.id,
            deleteValuesChampPerso: deleteValuesChampPerso
        })
    }

    return <BlockUI loading={changeChampPerso.isLoading || changeChampPersoSelectChamp.isLoading} className='rounded p-3'><div className='mt-5 mx-10'>
        <Row>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label className='required'>Nom du champ</Form.Label>
                    <Form.Control maxLength={40} className='form-control'
                                  {...register(`libelle`, {required : true, pattern : allowAllLetterAndAccentAndSpace()})}
                    />
                    {errors?.libelle && <Form.Text className='text-danger'>Ce champ est requis. Les characters spéciaux sont interdits.</Form.Text>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label className='required'>Type du champ</Form.Label>
                    <Form.Select disabled={champPerso?.id}
                                 {...register(`parametre.type`, {required: true})}
                    >
                        <option
                            // onClick={() => {
                            //     setStateTypeFiels(arrayTypesFields()[0]);
                            // }}
                            value="text">Texte sur 1 ligne
                        </option>
                        <option
                            value="textarea">Texte multiligne
                        </option>
                        <option
                            value="number">Nombre
                        </option>
                        <option
                            value="date">Date
                        </option>
                        <option
                            value="dateTime">Date et heure
                        </option>
                        <option
                            value="select">Liste déroulante
                        </option>
                        <option
                            value="selectMultiple">Liste déroulante choix multiple
                        </option>
                        <option
                            value="choice">Choix multiple
                        </option>
                        <option
                            value="checkbox">Cases à cocher
                        </option>
                    </Form.Select>
                    {errors?.parametre?.type && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </FormGroup>
            </Col>
            {
                type == "select" ?
                    <Row className="d-flex flex-wrap justify-content-center">
                        <Col sm={12} md={12} lg={6}>
                            <Form.Group
                                className='mt-5 bg-white m-w-200px p-5 rounded'>
                                <Form.Label className='required'>Champs de la liste déroulante</Form.Label>
                                <SelectChampsPersoTab valuesChampPersoAnnuaire={valuesChampPersoAnnuaire} id={champPerso?.id} onSaveTab={onSaveTab} deleteValuesChampPerso={deleteValuesChampPerso} setDeleteValuesChampPerso={setDeleteValuesChampPerso} getValues={getValues} setValue={setValue} usedValues={usedValues} setUpdate={setUpdateChoice} control={control} register={register} name='parametre.tabSelect' rules={{ required: true }} />
                                {errors?.parametre?.tabSelect && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                            </Form.Group>
                        </Col>
                    </Row>
                    :
                    ""
            }
            {
                type == "selectMultiple" ?
                    <Row className="d-flex flex-wrap justify-content-center">
                        <Col sm={12} md={12} lg={6}>
                            <Form.Group
                                className='mt-5 bg-white m-w-200px p-5 rounded'>
                                <Form.Label className='required'>Champs de la liste déroulante</Form.Label>
                                <SelectChampsPersoTab valuesChampPersoAnnuaire={valuesChampPersoAnnuaire} id={champPerso?.id} onSaveTab={onSaveTab} deleteValuesChampPerso={deleteValuesChampPerso} setDeleteValuesChampPerso={setDeleteValuesChampPerso} getValues={getValues} setValue={setValue} usedValues={usedValues} setUpdate={setUpdateChoice} control={control} register={register} name='parametre.tabSelect' rules={{ required: true }} />
                                {errors?.parametre?.tabSelect && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                            </Form.Group>
                        </Col>
                    </Row>
                    :
                    ""
            }
            {
                type == "choice" ?
                    <Row className="d-flex flex-wrap justify-content-center">
                        <Col sm={12} md={12} lg={6}>
                            <Form.Group
                                className='mt-5 bg-white m-w-200px p-5 rounded'>
                                <Form.Label className='required'>Choix possibles</Form.Label>
                                <SelectChampsPersoTab valuesChampPersoAnnuaire={valuesChampPersoAnnuaire} id={champPerso?.id} onSaveTab={onSaveTab} deleteValuesChampPerso={deleteValuesChampPerso} setDeleteValuesChampPerso={setDeleteValuesChampPerso} getValues={getValues} setValue={setValue} usedValues={usedValues} setUpdate={setUpdateChoice} control={control} register={register} name='parametre.tabChoice' rules={{ required: true }} />
                                {errors?.parametre?.tabChoice && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={6} className="d-flex align-items-center">
                            <div className="btn-group mt-5 m-w-300px" data-kt-buttons="true"
                                 data-kt-buttons-target="[data-kt-button]">
                                {previewChoice}
                            </div>
                        </Col>
                    </Row>
                    :
                    ""
            }
            <Col sm={6}>
                <Form.Check
                    className='mt-5'
                    type='switch'
                    label='Visible dans le suivi des invitations'
                    {...register(`parametre.isAfficheSuivi`)}
                />
            </Col>
            {
                isAfficheSuivi ?
                    <Col sm={6}>
                        <Form.Group
                            className='mt-5 reponsiveMultipleSelect'>
                            <Form.Label className='required'>Types d'invitation ou le champ est visible</Form.Label>
                            <TypeInvitationSelectionMultiple control={control} name='parametre.tabTypeIsAfficheSuivi' rules={{validate: (value, formValues) => value != null && Object.keys(value).length > 0}} />
                            {errors?.parametre?.tabTypeIsAfficheSuivi && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </Form.Group>
                    </Col> :
                    ""
            }



            {/*<Col sm={12}>*/}
            {/*    <FormGroup as={Col}>*/}
            {/*        <Form.Label className='required'>Valeur</Form.Label>*/}
            {/*        {stateTypeFields}*/}
            {/*        {errors?.parametre?.value && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}*/}
            {/*    </FormGroup>*/}
            {/*</Col>*/}
        </Row>
        <div className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='btn-md mb-2'
                onClick={handleSubmit(handleSubmitSave)}
            >{champPerso?.id != null ? 'Enregistrer' : 'Ajouter'}</Button>
            <span className="m-2"></span>
            {champPerso?.id == null && <Button
                variant='danger'
                className='btn-md mb-2'
                onClick={() => {
                    setHiddenEditLines(false)
                    toggleAccordionKey('new')
                }}
            >Annuler</Button>
            }
        </div>
    </div></BlockUI>;

}

export default ChampsPersonnaliseSettings;