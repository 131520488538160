import useGetParametresAgence from "@requests/parametres/agence/useGetParametresAgence";
import BlockUI from "@components/BlockUI/BlockUI";
import { Alert, Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {useEffect, useMemo} from "react";
import useSetParametresAgence from "@requests/parametres/agence/useSetParametresAgence";
import {codePostalPattern, emailPattern, telPattern, urlPattern} from "../../../functions/patterns";
import FormImagePicker from "../../../components/form/FormImagePicker/FormImagePicker";
import FormTypeahead from "../../../components/form/FormTypeahead/FormTypeahead";
import useGetVilleFromCodePostal from "../../../requests/geoname/useGetVilleFromCodePostal";
import {useDebounce} from "use-debounce";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import parametreProxy from "../../../proxies/parametre";

function AgenceSettings() {
    const parametresAgence = useGetParametresAgence();
    const setParametresAgence = useSetParametresAgence();
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, control, watch, setError, clearErrors } = useForm();
    const code = watch('codePostal');
    const ville = watch('ville');
    const id = watch('id');
    const [searchVilleParams] = useDebounce(useMemo(() => ({code, ville}), [code, ville]), 300);
    const autocompletionCodePostal = useGetVilleFromCodePostal({...searchVilleParams});

    useEffect(() => {
        reset(parametresAgence.data);
    }, [parametresAgence.data]);

    useEffect(() => {
        parametreProxy.isModifie = JSON.stringify(watch()) != JSON.stringify(parametresAgence?.data);
    }, [watch()])

    if (parametresAgence.isError)
        return <Alert variant='danger'>{parametresAgence.error?.message}</Alert>;

    return <BlockUI loading={parametresAgence.isLoading || setParametresAgence.isLoading}>
        <Row>
            <Col sm={2} className="justify-content-center d-flex">
                <FormImagePicker type={"logoAgence"} setValue={setValue} getValues={getValues} control={control} name='upload' />
            </Col>
            <Col sm={10}>
                <Form.Group className='mt-5'>
                    <Form.Label>Responsable</Form.Label>
                    <Form.Control maxLength={70} className='form-control-solid' {...register('prenomNomResponsable', {required : true})} />
                    {errors.prenomNomResponsable && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <Row className='mt-5'>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control maxLength={50} className='form-control-solid' {...register('email', { pattern: emailPattern()} )} />
                    {errors.email && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                </Form.Group>
            </Col>
            <Col sm={6} className='mt-5 mt-sm-0'>
                <Form.Group>
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control
                        maxLength={14}
                        onKeyUp={(e) => {
                            e.target.value = e.target.value.replace(/[\.,\-\+]/g, '');
                            let countNumber = 0;
                            for (let i=0; i<e.target.value.length; i++ ){
                                if (!isNaN(parseInt(e.target.value[i]))){
                                    countNumber = countNumber +1;
                                }
                            }
                            if (countNumber > 10){
                                setError("tel")
                            }else if (countNumber != 10 && countNumber != 0){
                                setError("tel")
                            }else {
                                clearErrors("tel")
                            }
                        }}
                        className='form-control-solid' {...register('tel', { pattern : telPattern()})} />
                    {errors.tel && <Form.Text className='text-danger'>Merci d'indiquer un numéro valide</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col className='mt-5'>
                <Form.Group>
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control
                        maxLength={200}
                        as='textarea'
                        className='form-control-solid'
                        rows={5}
                        {...register('adresse')}
                    />
                </Form.Group>
            </Col>
            <Col className='mt-5' md={3}>
                <Stack gap={3}>

                    <Form.Group>
                        <Form.Label>Code postal</Form.Label>
                        <FormTypeahead solid
                                       key={id}
                                       maxLength={5}
                                       id='codePostal'
                                       control={control}
                                       name='codePostal'
                                       rules={{ required: false, pattern: codePostalPattern() }}
                                       options={autocompletionCodePostal.data?.postalCodes}
                                       autocompleteDisabled={false}
                                       labelKey={option => option.postalCode}
                                       textKey={option => `${option.postalCode} - ${option.placeName}`}
                                       filterBy={() => true}
                                       onSelected={(selected) => {
                                           if (selected && selected.postalCode && selected.placeName){
                                               setValue("codePostal", selected.postalCode.slice(0,5))
                                               setValue("ville", selected.placeName)
                                           }

                                       }}
                        />
                        {errors.codePostal && <Form.Text className='text-danger'>Code postal invalide</Form.Text>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Ville</Form.Label>
                        <Form.Control className='form-control-solid' {...register('ville')} />
                    </Form.Group>
                </Stack>
            </Col>
        </Row>
        <Form.Group className='mt-5'>
            <Form.Label>Site web</Form.Label>
            <Form.Control maxLength={200} className='form-control-solid' {...register('urlSiteWeb', {pattern: urlPattern()})} />
            {errors.urlSiteWeb && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
        </Form.Group>


        <Card className='bg-light card-flush mt-10'>
            <Card.Body>
                <h3>Réseaux sociaux</h3>
                <Row className='mt-5'>
                    <Col sm={6}>
                        <Form.Group>
                            <i className="fab fa-linkedin me-2"></i>
                            <Form.Label>Linkedin</Form.Label>
                            <Form.Control maxLength={300} className='form-control-solid' {...register('urlLinkedin', {pattern: urlPattern()})} />
                            {errors.urlLinkedin && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            <i className="fab fa-instagram me-2"></i>
                            <Form.Label>Instagram</Form.Label>
                            <Form.Control maxLength={300} className='form-control-solid' {...register('urlInstagram', {pattern: urlPattern()})} />
                            {errors.urlInstagram && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            <i className="fab fa-youtube me-2"></i>
                            <Form.Label>Youtube</Form.Label>
                            <Form.Control maxLength={300} className='form-control-solid' {...register('urlYoutube', {pattern: urlPattern()})} />
                            {errors.urlYoutube && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col sm={6} className='mt-3 mt-sm-0'>
                        <Form.Group>
                            <i className="fab fa-twitter me-2"></i>
                            <Form.Label>Twitter</Form.Label>
                            <Form.Control maxLength={300} className='form-control-solid' {...register('urlTwitter', {pattern: urlPattern()})} />
                            {errors.urlTwitter && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            <i className="fab fa-tiktok me-2"></i>
                            <Form.Label>TikTok</Form.Label>
                            <Form.Control maxLength={300} className='form-control-solid' {...register('urlTiktok', {pattern: urlPattern()})} />
                            {errors.urlTiktok && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            <i className="fab fa-facebook me-2"></i>
                            <Form.Label>Facebook</Form.Label>
                            <Form.Control maxLength={300} className='form-control-solid' {...register('urlFacebook', {pattern: urlPattern()})} />
                            {errors.urlFacebook && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        <div className='d-flex justify-content-center mt-10'>
            <Button
                variant='secondary'
                className='px-20'
                onClick={handleSubmit(setParametresAgence.mutate)}
            >
                Enregistrer les modifications
            </Button>
        </div>
    </BlockUI>;
}

export default AgenceSettings;