import {useEffect, useMemo, useState} from "react";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import {useSnapshot} from "valtio";

export default function CheckParticipantsMobile({color,dataCurrentTableParticipantArrivantATable, tabTables, currentTime, tour, numTable, nbTours, nomCurrentTable}) {
    const speedSnap = useSnapshot(speedBusinessProxy);
    const [update, setUpdate] = useState(0);
    const participantsMemo = useMemo(() => {
        return  dataCurrentTableParticipantArrivantATable.map(item => {
            return {
                participant: item?.infoParticipant?.participant.personne.prenom + " " + item?.infoParticipant?.participant.personne.nom,
                isCheck: speedSnap?.tabParticipantArriveTable.includes(String(item?.infoParticipant?.id)) ? true : false,
                idParticipant: item?.infoParticipant?.id
            }
        })
    }, [dataCurrentTableParticipantArrivantATable, tabTables, speedSnap.tabParticipantArriveTable])

    useEffect(() => {
        speedSnap.websocket.sendParticipant({
            action: "participantsArrivesTable",
            numTour : tour,
            idTable: numTable
        })
    }, [update, tour]);


    function setParticipantArrive(info){
        speedSnap.websocket.sendParticipant({
            action: "traitementParticipant",
            idTable : info?.idTable,
            numTour : info?.numTour,
            idParticipant : info?.idParticipant
        })
    }

    return <>
        <div style={{
            height: 120,
            width: "100%",
            marginBottom: 50,
            backgroundColor: "var(--primary-color)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flexWrap: "wrap",
        }}>
            <div><h2 style={{
                width: "100%",
                fontSize: 22,
                color: "white",
                marginBottom:0,
                textTransform: "uppercase",
                textAlign:"center"
            }}>Qui est arrivé ?</h2>
                <span style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "white",
                    textAlign: "center"
                }}>Tour {tour}/{nbTours} - Table : {nomCurrentTable}</span>
            </div>
            <span style={{
                position: "absolute",
                height: "40px",
                width: 380,
                bottom: -20,
                backgroundColor: "var(--primary-color)",
                left: "calc(50% - 190px)",
                borderRadius: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <span style={{
                    fontSize: 14,
                    color: "white",
                    fontWeight: "bold"
                }}>Temps changement de table : {currentTime} secondes</span>
            </span>
        </div>
        <p style={{
            fontSize: 16,
            width: "100%",
            padding: 10,
            textAlign: "center"
        }}>C'est à toi de jouer, check les présents :</p>
        <div style={{
            paddingBottom: 20,
            textAlign: "center"
        }}>
                        <span> <i className="fa fa-users fa-2x me-2" style={{
                            color: color
                        }}></i> <span style={{
                            fontSize: 18,
                            fontWeight: "bold"
                        }}>Participants</span></span>
        </div>
        {participantsMemo.map((item, index) => {
            return <span className={`${item.isCheck ? 'bg-success' : 'bg-primary'} mb-3`}
                         onClick={() => {
                             let tmpParticipant = [...participantsMemo];
                             tmpParticipant[index].isCheck = !tmpParticipant[index].isCheck
                             setUpdate(update+1)
                             setParticipantArrive({
                                 idTable: numTable,
                                 numTour:tour,
                                 idParticipant:tmpParticipant[index].idParticipant
                             })
                         }}
                         style={{
                             padding: "5px 5px 5px 10px",
                             fontSize: 14,
                             fontWeight: "bold",
                             color: "white",
                             width: 200,
                             display: "flex",
                             borderRadius: 30,
                             justifyContent: "space-between",
                             alignItems: "center",
                             margin: "auto",
                             cursor: "pointer"
                         }}>
                <span style={{
                    overflow: "hidden",
                    textOverflow: 'ellipsis',
                    width:180,
                    textWrap:"nowrap"
                }}>{item.participant}</span> <i style={{
                fontSize: 26,
                color: "white"
            }} className="ms-3 fa fa-check-circle"></i>
        </span>
        })}
        <p style={{
            fontSize: 16,
            width: "100%",
            padding: 10,
            textAlign: "center"
        }}>{(countCheck(participantsMemo) > 0 ) ? ((countCheck(participantsMemo) < participantsMemo.length) ? "Votre table commence à se compléter 🎉" : "Vous êtes au complet, c’est parti ! 🚀✨ ") : ""}</p>

    </>;

    function countCheck(arr){
        let counts = 0;
        for (const num of arr) {
            if (num.isCheck){
                counts=counts+1
            }
        }
        return counts
    }
}