import VertilcalCarousel from "../../../../components/VerticalCarousel/VertilcalCarousel";
import {useEffect, useMemo, useState} from "react";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import tinycolor from "tinycolor2";

export default function TableChangementTable({speed, nbTablesTotales, indexParticipants, nbTours, arrayDataParticipantSansMaitre, arrayDataParticipantMaitreSuppleant, arrayDataParticipantMaitre, nomTable, placementParPersonne, indexTour, nomTables}) {
    const speedSnap = useSnapshot(speedBusinessProxy);
    const primaryColor = getComputedStyle(document.body).getPropertyValue("--primary-color");
    const colorIsDark = tinycolor(getComputedStyle(document.body).getPropertyValue("--primary-color")).getBrightness()<145;
    const primaryLighten = tinycolor(getComputedStyle(document.body).getPropertyValue("--primary-color")).lighten().toString();
    const primaryDarken = tinycolor(getComputedStyle(document.body).getPropertyValue("--primary-color")).darken().toString();
    const arrayHtmlPersonnesInTheTables = useMemo(() => {
        let tmpHtml = [];
        indexParticipants.forEach(index => {
            if (index >= 0){
                if (typeof arrayDataParticipantSansMaitre[index] !== "undefined"){
                    if (!speedSnap?.tabParticipantArrive.includes(String(arrayDataParticipantSansMaitre[index].id))){
                        tmpHtml.push(<div style={{
                            height: nbTablesTotales > 37 ? 41 : (nbTablesTotales > 23 ? 46 : 51)
                        }}>
                            <div style={{
                                marginTop: 3,
                                height: nbTablesTotales > 37 ? 20 : (nbTablesTotales > 23 ? 22 : 25),
                                width: nbTablesTotales > 37 ? 130 : (nbTablesTotales > 23 ? 160 : "190px"),
                                transform: "skew(-20deg)",
                                backgroundColor: colorIsDark ? primaryLighten : primaryDarken
                            }}>
                <span style={{
                    transform: "skew(20deg)",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textWrap:"nowrap",
                    paddingLeft:2,
                    display: "inline-block",
                    fontSize: nbTablesTotales > 37 ? 12 : (nbTablesTotales > 23 ? 16 : 18),
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                    width: nbTablesTotales > 37 ? 130 : (nbTablesTotales > 23 ? 160 : "190px")
                }}>{arrayDataParticipantSansMaitre[index]?.participant?.personne?.prenom} {arrayDataParticipantSansMaitre[index]?.participant?.personne?.nom}</span>
                            </div>
                            <div style={{
                                backgroundColor: primaryColor,
                                marginLeft: -4,
                                width: nbTablesTotales > 37 ? 120 : (nbTablesTotales > 23 ? 158 : 188)
                            }} id={`${nbTablesTotales > 37 ? "pointer" : (nbTablesTotales > 23 ? "pointer3" : "pointer2")}`}>
                 <span style={{
                     display: "inline-block",
                     fontSize: nbTablesTotales > 37 ? 12 : (nbTablesTotales > 23 ? 16 : 18),
                     color: "white",
                     textAlign: "end",
                     width: nbTablesTotales > 37 ? 120 : (nbTablesTotales > 23 ? 158 : 188)
                 }}>
                     <img src="/images/tripleFlecghe.png" height={12} alt=""/> {typeof placementParPersonne[index][(indexTour + 1)] !== "undefined" ? nomTables[placementParPersonne[index][(indexTour + 1)]] : ""}
                 </span>
                            </div>
                        </div>)
                    }
                }
            } else {
                if (index >= (-nbTablesTotales)){
                    if (arrayDataParticipantMaitre[index+nbTablesTotales]){
                        if (!speedSnap?.tabParticipantArrive.includes(String(arrayDataParticipantMaitre[index+nbTablesTotales].id))){
                            tmpHtml.push(<div style={{
                                height: nbTablesTotales > 37 ? 41 : (nbTablesTotales > 23 ? 46 : 51)
                            }}>
                                <div style={{
                                    marginTop: 3,
                                    height: nbTablesTotales > 37 ? 20 : (nbTablesTotales > 23 ? 22 : 25),
                                    width: nbTablesTotales > 37 ? 130 : (nbTablesTotales > 23 ? 160 : "190px"),
                                    transform: "skew(-20deg)",
                                    backgroundColor: colorIsDark ? primaryLighten : primaryDarken
                                }}>
                <span style={{
                    transform: "skew(20deg)",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textWrap:"nowrap",
                    paddingLeft:2,
                    display: "inline-block",
                    fontSize: nbTablesTotales > 37 ? 12 : (nbTablesTotales > 23 ? 16 : 18),
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                    width: nbTablesTotales > 37 ? 130 : (nbTablesTotales > 23 ? 160 : "190px")
                }}>{arrayDataParticipantMaitre[index+nbTablesTotales]?.participant?.personne?.prenom} {arrayDataParticipantMaitre[index+nbTablesTotales]?.participant?.personne?.nom}</span>
                                </div>
                                <div style={{
                                    backgroundColor: primaryColor,
                                    marginLeft: -4,
                                    width: nbTablesTotales > 37 ? 120 : (nbTablesTotales > 23 ? 160 : 188)
                                }} id={`${nbTablesTotales > 37 ? "pointer" : (nbTablesTotales > 23 ? "pointer3" : "pointer2")}`}>
                 <span style={{
                     display: "inline-block",
                     fontSize: nbTablesTotales > 37 ? 12 : (nbTablesTotales > 23 ? 16 : 18),
                     color: "white",
                     textAlign: "end",
                     width: nbTablesTotales > 37 ? 120 : (nbTablesTotales > 23 ? 158 : 188)
                 }}>
                     <img src="/images/tripleFlecghe.png" height={12} alt=""/> {typeof placementParPersonne[index][(indexTour + 1)] !== "undefined" ? nomTables[placementParPersonne[index][(indexTour + 1)]] : ""}
                 </span>
                                </div>
                            </div>)
                        }
                    }
                }else {
                    if (arrayDataParticipantMaitreSuppleant[Math.abs(index+nbTours)]){
                        if (!speedSnap?.tabParticipantArrive.includes(String(arrayDataParticipantMaitreSuppleant[Math.abs(index+nbTours)].id))){
                            tmpHtml.push(<div style={{
                                height: nbTablesTotales > 37 ? 41 : (nbTablesTotales > 23 ? 46 : 51)
                            }}>
                                <div style={{
                                    marginTop: 3,
                                    height: nbTablesTotales > 37 ? 20 : (nbTablesTotales > 23 ? 22 : 25),
                                    width: nbTablesTotales > 37 ? 130 : (nbTablesTotales > 23 ? 160 : "190px"),
                                    transform: "skew(-20deg)",
                                    backgroundColor: colorIsDark ? primaryLighten : primaryDarken
                                }}>
                <span style={{
                    transform: "skew(20deg)",
                    textOverflow: "ellipsis",
                    textWrap:"nowrap",
                    overflow: "hidden",
                    paddingLeft:2,
                    display: "inline-block",
                    fontSize: nbTablesTotales > 37 ? 12 : (nbTablesTotales > 23 ? 16 : 18),
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                    width: nbTablesTotales > 37 ? 130 : (nbTablesTotales > 23 ? 160 : "190px")
                }}>{arrayDataParticipantMaitreSuppleant[Math.abs(index+nbTours)]?.participant?.personne?.prenom} {arrayDataParticipantMaitreSuppleant[Math.abs(index+nbTours)]?.participant?.personne?.nom}</span>
                                </div>
                                <div style={{
                                    backgroundColor: primaryColor,
                                    marginLeft: -4,
                                    width: nbTablesTotales > 37 ? 120 : (nbTablesTotales > 23 ? 160 : 188)
                                }} id={`${nbTablesTotales > 37 ? "pointer" : (nbTablesTotales > 23 ? "pointer3" : "pointer2")}`}>
                 <span style={{
                     display: "inline-block",
                     fontSize: nbTablesTotales > 37 ? 12 : (nbTablesTotales > 23 ? 16 : 18),
                     color: "white",
                     textAlign: "end",
                     width: nbTablesTotales > 37 ? 120 : (nbTablesTotales > 23 ? 158 : 188)
                 }}>
                     <img src="/images/tripleFlecghe.png" height={12} alt=""/> {typeof placementParPersonne[index][(indexTour + 1)] !== "undefined" ? nomTables[placementParPersonne[index][(indexTour + 1)]] : ""}
                 </span>
                                </div>
                            </div>)
                        }
                    }
                }
            }
        })
        return tmpHtml;
    }, [speedSnap?.tabParticipantArrive])

    return <>{nbTablesTotales > 37 ?
        <div style={{
            height: speed?.nombrePersonnesParTableChoisies == 2 ? 160 : 205,
            width: 150,
            paddingLeft:0,
            border: "gray 2px solid",
            borderRadius: "0 0 6px 6px",
            paddingRight: 0
        }}>
            <div style={{
                height: 59,
                width: "100%"
            }}>
                <div style={{
                    height: 20,
                    width: "100%",
                    backgroundColor: "#575756"
                }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                    color: "white",
                    display: "inline-block"
                }}>Table actuelle</span>
                </div>
                <div style={{
                    height: 24,
                    width: "100%",
                    backgroundColor: "white",
                    color: "#575756",
                    fontSize: 14,
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "inline-block"
                }}>
                    {nomTable.toUpperCase()}
                </div>
                <img style={{
                    verticalAlign: "inherit"
                }} src="/images/triangle.svg" alt="triangle" width="100%" height={15}/>
            </div>

            <div id="scroll-container" style={{
                width: 150,
                paddingLeft: 9,
                overflowY: "hidden",
                height: 145
            }}>
                <div id="scroll-text">
                    <VertilcalCarousel isSlide={arrayHtmlPersonnesInTheTables.length > 3} itemHeight={45} data={arrayHtmlPersonnesInTheTables}/>
                </div>
            </div>
        </div>
        :
        <>{
            nbTablesTotales > 23 ?
                <div style={{
                    height: speed?.nombrePersonnesParTableChoisies == 2 ? 175 : 220,
                    width: 180,
                    paddingLeft:0,
                    border: "gray 2px solid",
                    borderRadius: "0 0 6px 6px",
                    paddingRight: 0
                }}>
                    <div style={{
                        height: 59,
                        width: "100%"
                    }}>
                        <div style={{
                            height: 20,
                            width: "100%",
                            backgroundColor: "#575756"
                        }}>
                <span style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                    color: "white",
                    display: "inline-block"
                }}>Table actuelle</span>
                        </div>
                        <div style={{
                            height: 20,
                            width: "100%",
                            backgroundColor: "white",
                            color: "#575756",
                            fontSize: 14,
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "inline-block"
                        }}>
                            {nomTable.toUpperCase()}
                        </div>
                        <img style={{
                            verticalAlign: "inherit"
                        }} src="/images/triangle.svg" alt="triangle" width="100%" height={15}/>
                    </div>
                    <div id="scroll-container" style={{
                        width: 180,
                        paddingLeft: 9,
                        overflowY: "hidden",
                        height: 155
                    }}>
                        <div id="scroll-text">
                            <VertilcalCarousel isSlide={arrayHtmlPersonnesInTheTables.length > 3} itemHeight={49} data={arrayHtmlPersonnesInTheTables}/>
                        </div>
                    </div>

                </div>
                :
                <div style={{
                    height: speed?.nombrePersonnesParTableChoisies == 2 ? 185 : 250,
                    width: 215,
                    paddingLeft:0,
                    border: "gray 2px solid",
                    borderRadius: "0 0 6px 6px",
                    paddingRight: 0
                }}>
                    <div style={{
                        height: 69,
                        width: "100%"
                    }}>
                        <div style={{
                            height: 25,
                            width: "100%",
                            backgroundColor: "#575756"
                        }}>
                <span style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                    color: "white",
                    display: "inline-block"
                }}>Table actuelle</span>
                        </div>
                        <div style={{
                            height: 29,
                            width: "100%",
                            backgroundColor: "white",
                            color: "#575756",
                            fontSize: 18,
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "inline-block"
                        }}>
                            {nomTable.toUpperCase()}
                        </div>
                        <img style={{
                            verticalAlign: "inherit"
                        }} src="/images/triangle.svg" alt="triangle" width="100%" height={15}/>
                    </div>
                        <div id="scroll-container" style={{
                            width: 210,
                            paddingLeft:9,
                            overflowY: "hidden",
                            height:165
                        }}>
                            <div id="scroll-text">

                                <VertilcalCarousel isSlide={arrayHtmlPersonnesInTheTables.length > 3} itemHeight={52} data={arrayHtmlPersonnesInTheTables}/>

                            </div>
                        </div>
                </div>

        }
        </>

    } </>
}