import {useEffect, useState} from "react";
import {URLWebSocketSpeed} from "../../../config";
import {v4 as uuidv4} from "uuid";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import moment from "moment";

export default function useWebSocket(refSpeed){
    const [reloadWebSocket, setReloadWebSocket] = useState(0);
    const [websocketIsConnecte, setWebsocketIsConnecte] = useState(false);
    useEffect(() => {
        if (refSpeed){
            let websocket = new WebSocket(URLWebSocketSpeed)
            let idConnexion = null;
            websocket.addEventListener("close", (event) => {
                setWebsocketIsConnecte(false)
                setTimeout(function (){
                    setReloadWebSocket(uuidv4());
                    speedBusinessProxy.websocket.send = (cache) => {
                        console.log("ws speed hs")
                    };
                },3000)
            });
            websocket.onopen = (ev) => {
                setWebsocketIsConnecte(true)
                console.log('connected ws speed')
                speedBusinessProxy.websocket.send = (cache) => {
                    websocket.send(b64EncodeUnicode(b64EncodeUnicode(JSON.stringify({
                        action : "traitementRencontre",
                        idConnexion : idConnexion,
                        refRencontresSpeedBusiness : refSpeed,
                        infoRencontre : cache?.objectCurrentPresentationSpeed
                    }))))
                }
                speedBusinessProxy.websocket.sendParticipant = (cache) => {
                    websocket.send(b64EncodeUnicode(b64EncodeUnicode(JSON.stringify({
                        action : cache?.action,
                        idConnexion : idConnexion,
                        refRencontresSpeedBusiness : refSpeed,
                        idTable : cache?.idTable,
                        numTour : cache?.numTour,
                        idParticipant:cache?.idParticipant
                    }))))
                }
            }
            websocket.onmessage = (event) => {
                let data  = (JSON.parse(b64DecodeUnicode(b64DecodeUnicode(event?.data))))
                if(data?.success == false){
                    websocket.close()
                }
                else if (data?.action == "connexion" && data?.response?.idConnexion){
                    idConnexion = data?.response?.idConnexion
                    websocket.send(b64EncodeUnicode(b64EncodeUnicode(JSON.stringify({
                        action : "identification",
                        idConnexion : data?.response?.idConnexion,
                        refRencontresSpeedBusiness : refSpeed
                    }))))
                }
                else if(data?.action == "identification"){
                    speedBusinessProxy.objectCurrentPresentationSpeed = data?.response?.infoRencontre;
                    speedBusinessProxy.diffServeurTimestamp = data?.response?.timestamp - moment().unix();
                }
                else if (data?.action == "controlePresence"){
                    websocket.send(b64EncodeUnicode(b64EncodeUnicode(JSON.stringify({
                        action : "controlePresence",
                        idConnexion : idConnexion
                    }))))
                    speedBusinessProxy.diffServeurTimestamp = data?.timestamp - moment().unix();
                }
                else if(data?.action == "majEtatRencontre"){
                    speedBusinessProxy.objectCurrentPresentationSpeed = data?.response?.infoRencontre;
                    speedBusinessProxy.diffServeurTimestamp = data?.response?.timestamp - moment().unix();
                }else if (data?.action == "majEtatParticipant"){
                    speedBusinessProxy.updateParticipantsArrive = uuidv4();
                }else if (data?.action == "participantsArrivesTour"){
                    if (data?.response?.tabIdsParticipants){
                        let tmpArrivee = [];
                        Object.values(data?.response?.tabIdsParticipants).forEach(item => {
                            tmpArrivee.push(...Object.keys(item))
                        })
                        speedBusinessProxy.tabParticipantArrive = tmpArrivee;
                    }else {
                        speedBusinessProxy.tabParticipantArrive = []
                    }
                }else if (data?.action == "participantsArrivesTable"){
                    if (data?.response?.tabIdsParticipants){
                        let tmpArrivee = [];
                        Object.keys(data?.response?.tabIdsParticipants).forEach(item => {
                            tmpArrivee.push(item)
                        })
                        speedBusinessProxy.tabParticipantArriveTable = tmpArrivee;
                    }else {
                        speedBusinessProxy.tabParticipantArriveTable = [];
                    }
                }
            }
            return () => {
                websocket.close()
            }
        }
        }, [reloadWebSocket, refSpeed])

    function b64EncodeUnicode(str) {
        const uriEncoding = encodeURIComponent(str)
            .replace(/%([0-9A-F]{2})/g, function(match, p1) {
                    return String.fromCharCode(
                    parseInt(p1, 16)
                );
            }
            );
        return btoa(uriEncoding);
    }

    function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    return websocketIsConnecte;
}